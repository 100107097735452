/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Workspace.scss";
import "../../components/back-to-bottom/BackToBottom.scss";
import relax from "../../assets/images/workspace/icons/relax.png";
import pro from "../../assets/images/workspace/icons/pro.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import defaultimg from "../../assets/images/workspace/default-img.png";
import defaultimgbig from "../../assets/images/workspace/workspace-generating box.png";
import brokenen from "../../assets/images/workspace/broken-eng.gif";
import brokench from "../../assets/images/workspace/broken-ch.gif";
import downarrow from "../../assets/images/icons/down-arrow.png";
import dots from "../../assets/images/workspace/dots.gif";
import dots1 from "../../assets/images/workspace/upscale-gif.gif";
import alerticon from "../../assets/images/workspace/icons/alert4.png";
import cancelButton from "../../assets/images/icons/Vector.png";
import deleted from "../../assets/images/workspace/deleted-image.jpg";
import cross from "../../assets/images/workspace/icons/cross-icon.png";
import fav from "../../assets/images/workspace/icons/feed-fav.png";
import fav1 from "../../assets/images/workspace/icons/feed-fav1.png";
import fav2 from "../../assets/images/workspace/icons/feed-fav2.png";
import del from "../../assets/images/workspace/icons/feed-del.png";
import del1 from "../../assets/images/workspace/icons/feed-del1.png";
import del2 from "../../assets/images/workspace/icons/feed-del2.png";
import refreshButton from "../../assets/images/icons/refresh_button.png";
import DesignLibraryComopnent from "../../components/WorkspaceComponents/DesignLibraryComponent/DesignLibraryComopnent";
import TiresPopUp from "../../components/WorkspaceComponents/TiresComponent/TiresPopUp";
import { DesignLibraryContext } from "../../store/DesignLibraryData";
import ToolSetComponent from "../../components/WorkspaceComponents/ToolsetComponent/ToolSetComponent";
import AtmospherePopUp from "../../components/WorkspaceComponents/AtmosphereComponent/AtmospherePopUp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../store/UserDetailsStore";
import ModeTooltip from "../../components/Tooltips/ModeTooltip";
import { AIRI_BASE_URL_WS, BaseURL } from "../../utils/api-constant";
import { ToolsetDataContext } from "../../store/WorkSpaceToolsetData";
import { RenderToolsetContext } from "../../store/RenderToolsetData";
import { EditToolsetContext } from "../../store/EditToolsetData";
import { UpscaleToolsetContext } from "../../store/UpscaleToolsetData";
import {
  GenerateWorkFlowDesign,
  GenerateWorkFlowImage,
  GenerateWorkFlowText,
  GenerateWorkFlowUpscale,
  CreateOrUpdateProject,
  GenerateWorkFlowUpscaleFeed,
  GetOneRecordApi,
  UpdateFavoriteImage,
  GenerateWorkFlowEraser,
  DeleteImagesGallery,
  GenerateWorkFlowUpscaleCreative,
  GenerateWorkFlowUpscaleCharacter,
  GenerateWorkFlowUpscaleExtend,
} from "../../utils/apiCalls";
import WorkSpaceFeedPopUp from "../../components/WorkspaceComponents/workspacefeedpopup/WorkSpaceFeedPopUp";
import GlobalErrorPopup from "../../components/Global-Error-Popup/GlobalErrorPopup";
import PLanEnd from "../../components/plan-end/PLanEnd";
import { CurrentProjectContext } from "../../store/CurrentProjectData";
import ImageMaskPopUp from "../../components/WorkspaceComponents/MaskComponent/ImageMaskPopUp";
import Confirm from "../../components/confirmModal/confirm";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { UserSocketContext } from "../../store/UserSocketData";
import FavoriteTooltip from "../../components/Tooltips/FavotiteTooltip";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { TeamDesignContext } from "../../store/TeamDesignData";
import { Popover } from "antd";
import { convertDateTime1 } from "../../utils/workspaceUtils";
import Button from "../../components/button";
import { processText } from "../../utils/commonUtils";

const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState(true);

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return { isOnline };
};

const Workspace = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const hasFetchedRef = useRef(false);
  const mostRecentImageRef = useRef(null);
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const { isOnline } = useNetworkStatus();
  // console.log(isOnline);
  const { setIsRedirect, responsiveData } = useContext(ResponsiveDataContext);
  const { designLibraryData } = useContext(DesignLibraryContext);
  const { isPrivilagesDetails, soloTeamData } = useContext(UserContext);
  const { toolsetData } = useContext(ToolsetDataContext);
  const { renderToolsetData, clearRenderToolsetData } =
    useContext(RenderToolsetContext);
  const { editToolsetData, clearEditToolsetData } =
    useContext(EditToolsetContext);
  const {
    upscaleToolsetData,
    updateUpscaleToolsetData,
    clearUpscaleToolsetData,
  } = useContext(UpscaleToolsetContext);
  const { updateCurrentProjectData } = useContext(CurrentProjectContext);
  const {
    updateSocketData,
    setSubscriptionLimit,
    openUserSocket,
    handleShowSubscription,
  } = useContext(UserSocketContext);
  const { apiCalledRef1, setIsGenCount, isTeamChanges } =
    useContext(TeamDesignContext);

  const activeToolsetLocal = JSON.parse(
    localStorage.getItem(`activeToolset_${lastPart}`)
  );
  const isActiveLanguage = localStorage.getItem("language");
  const exampleprojectsJson = JSON.parse(
    localStorage.getItem("exampleProjects")
  );
  const isLoginUser = localStorage.getItem("loginUser");
  const loginUserData = JSON.parse(localStorage.getItem("isLoginUser"));
  const designLocalBase = JSON.parse(
    localStorage.getItem(`designBaseimg_${lastPart}`)
  );
  const upscaleLocalBase = JSON.parse(
    localStorage.getItem(`upscaleBaseimg_${lastPart}`)
  );
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const uploadErrorKey = `upload_error_${lastPart}`;
  const atmosphereRefLocal = JSON.parse(
    localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
  );
  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const cameraLocalData = JSON.parse(
    localStorage.getItem(`cameraData_${lastPart}`)
  );
  let isTemplates =
    JSON.parse(localStorage.getItem(`isTemplate_${lastPart}`)) || {};
  const uploadError = localStorage.getItem(uploadErrorKey);
  let parsedUploadError;

  try {
    parsedUploadError = JSON.parse(uploadError);
  } catch (error) {
    console.error(`Error parsing JSON: ${error}`);
  }

  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [activeLearnBool, setActiveLearnBool] = useState({
    id: 1,
    boolean: false,
  });
  const [hasFetchedProjectDetails, setHasFetchedProjectDetails] =
    useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGeneratedAllImages, setIsGeneratedAllImages] = useState([]);
  const [generatedAllImages, setGeneratedAllImages] = useState([]);
  const [isShowLoadingCard, setIsShowLoadingCard] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showBrockenImages, setShowBrockenImages] = useState(false);
  const [isLimitPoup, setIsLimitPopup] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState("");
  const [isSetMessages, setIsSetMessages] = useState([]);
  const [isPageIndex, setIsPageIndex] = useState(0);
  const [isPageSize, setIsPageSize] = useState(12);
  const [isReloadFeed, setIsReloadFeed] = useState(false);
  const [allImagesFetched, setAllImagesFetched] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [isNewProjectName, setIsNewProjectName] = useState();
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isReferenceImg, setIsReferenceImg] = useState(false);
  const [isDesignToolsetBaseImg, setIsDesignToolsetBaseImg] = useState(false);
  const [isImageToolsetBaseImg, setIsImageToolsetBaseImg] = useState(false);
  const [isImageToolsetMaskImg, setIsImageToolsetMaskImg] = useState(false);
  const [isImageToolsetCNmg, setIsImageToolsetCNImg] = useState(false);
  const [isUpscaleToolsetBaseImg, setIsUpscaleToolsetBaseImg] = useState(false);
  const [showSingleCard, setShowSingleCard] = useState(false);
  const [isWebSocket, setIsWebSocket] = useState();
  const [isMessageStatus, setIsMessageStatus] = useState("");
  const [socketWorkflow, setSocketWorkflow] = useState("");
  const [socketTimer, setSocketTimer] = useState(0);
  const [isMessageId, setIsMessageId] = useState("");
  const [isNetworkLoad, setIsNetworkLoad] = useState("");
  const [isGpuLoad, setIsGpuLoad] = useState("");
  const [isNetworkLoadAlert, setIsNetworkLoadAlert] = useState(false);
  const [isGpuLoadAlert, setIsGpuLoadAlert] = useState(false);
  const [showMaxAlert, setShowMaxAlert] = useState(false);
  const [showMaxAlert1, setShowMaxAlert1] = useState(false);
  const [imageGenerated, SetImageGenerated] = useState(false);
  const [latestImageGeneratedName, SetLatestImageGeneratedName] = useState();
  const [isGptActive, setIsGptActive] = useState(false);
  const [isBeforeGenerateCount, setIsBeforeGenerateCount] = useState(0);
  const [isAfterGenerateCount, setIsAfterGenerateCount] = useState(0);
  const [isAfterGenerateCount1, setIsAfterGenerateCount1] = useState(0);
  const [receivedMessages, setReceivedMessages] = useState([]);
  let [maxCount, setMaxCount] = useState(0);
  const [targetId, setTargetId] = useState("");
  const [isTargetId, setIsTargetId] = useState("");
  const [timer, setTimer] = useState(0);
  const [timers, setTimers] = useState(0);
  // const [isGenerateIdWeb, setIsGenerateIdWeb] = useState([]);
  const [isActiveToolset, setIsActiveToolset] = useState(
    activeToolsetLocal?.toolsetActive ?? 0
  );
  const [showButton, setShowButton] = useState(false);
  const [isShowFeedPopup, setIsShowFeedPopup] = useState(false);
  const [popUpData, setPopUpData] = useState(null);
  const [editProjectPopup, setEditProjectPopup] = useState(false);
  const [remaining1, setRemaining1] = useState(0);
  const [deletedMediaId, setDeletedMediaId] = useState([]);
  const [finishStatus, setFinishStatus] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [isFavoriteImages, setIsFavoriteImages] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [templateSelection, setTemplateSelection] = useState(false);
  const [isNewProject, setIsNewProject] = useState(true);
  const [isExitTeam, setIsExitTeam] = useState(false);
  const [isUploadScaled, setIsUploadScaled] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [mediaIDTBD, setMediaIDTBD] = useState(0);
  const [alertText, setAlertText] = useState("");
  const [isParts, setIsParts] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [templateDisable, setTemplateDisable] = useState(false);
  const [highlightedMediaId, setHighlightedMediaId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorId, setErrorId] = useState(null);

  const arrayOfIds = exampleprojectsJson?.map((id) => id?.id);
  const isExample = arrayOfIds?.includes(Number(params?.id));

  const isLogin =
    isLoginUser !== "undefined"
      ? JSON.parse(isLoginUser)
      : localStorage.setItem("loginUser", false);

  useEffect(() => {
    clearRenderToolsetData();
    clearEditToolsetData();
    clearUpscaleToolsetData();
  }, []);

  useEffect(() => {
    if (!atmosphere) {
      setIsNewProject(true);
      setTimeout(() => {
        getCurrentProjectDetails();
        setTimeout(() => {
          setIsNewProject(false);
        }, 1000);
      }, 1000);
    } else {
      setIsNewProject(false);
      getCurrentProjectDetails();
    }
  }, [atmosphere]);

  useEffect(() => {
    if (isPrivilagesDetails?.manualPrompt === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  useEffect(() => {
    const targetElement = targetRef.current;
    if (targetElement) {
      targetElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (targetElement) {
        targetElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const designLibraryDisable = JSON.parse(
      localStorage.getItem(`designLibraryDisable_${lastPart}`)
    );
    if (designLibraryDisable && designLibraryDisable?.isDesign) {
      setTemplateDisable(true);
    } else {
      setTemplateDisable(false);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "activeTeam") {
        reloadFeed();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const reloadFeed = () => {
    setFirstLoad(true);
    setGeneratedAllImages([]);
    setIsReloadFeed(true);
    setIsGeneratedAllImages([]);
  };

  useEffect(() => {
    setIsActiveToolset(activeToolsetLocal?.toolsetActive ?? 0);
  }, [toolsetData]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const teamId = isActiveTeam?.teamId === 0.1 ? 0 : isActiveTeam?.teamId;
    const socket = new WebSocket(
      `wss://${AIRI_BASE_URL_WS}/websocket?teamId=${teamId}`,
      ["Bearer", token]
    );

    // const socket = new WebSocket(`wss://${AIRI_BASE_URL_WS}/websocket`, [
    //   "Bearer",
    //   token,
    // ]);

    const closeExistingSocket = isWebSocket;
    if (closeExistingSocket) {
      closeExistingSocket.close();
      setReceivedMessages([]); // Clear received messages when closing the socket
    }

    socket.onopen = () => {
      // console.log("WebSocket connected");
      setReceivedMessages([]); // Clear received messages when opening the socket
    };

    socket.onmessage = (event) => {
      const message = event.data;
      const regex = /API Count: (\d+)/;
      const match = message.match(regex);
      if (match && match[1]) {
        maxCount = parseInt(match[1]);
        setIsGenCount(maxCount);
        setMaxCount(maxCount);
      }
      getMessage(message);
    };

    socket.onclose = () => {
      // console.log("WebSocket connection closed");
    };

    setIsWebSocket(socket);

    return () => {
      socket.close();
    };
  }, []);

  if (isWebSocket && isWebSocket.readyState === WebSocket.OPEN) {
    // console.log("WebSocket connection is open.");
  } else {
    // console.log("WebSocket connection is not open.");
  }

  const getMessage = (message) => {
    const parts = message?.split(",");
    const status = parts[0]?.trim();
    const id = parts[1]?.split(":")[1]?.trim();
    const boolean = true;
    let singleCard = false;
    const isWorkflow = parts[2]?.split(":")[1]?.trim();
    const isTimer = parts[3]?.split(":")[1]?.trim();
    const pId = parts[4]?.split(":")[1]?.trim();
    const imageCount = parts[5]?.split(":")[1]?.trim();
    if (
      isWorkflow === "upscale" ||
      isWorkflow === "upscale_feed" ||
      isWorkflow === "outpainting" ||
      isWorkflow === "upscaleFeedPeople" ||
      isWorkflow === "upscaleFeedCreative"
    ) {
      singleCard = true;
    } else {
      singleCard = false;
    }
    const isBrockenImages = false;
    const regex = /API Count: (\d+)/;
    const match = status.match(regex);
    let apiCount;
    if (match && match[1]) {
      apiCount = parseInt(match[1]);
      setMaxCount(apiCount);
    }
    setIsParts(parts);
    // if (status === "Average GPU Load") {
    const loadMessage = parts[0]?.split(":")[0]?.trim();
    const loadStatus = parts[0]?.split(":")[1]?.trim();
    // Average GPU Load text:Normal
    // Average Network Load text:Normal
    if (loadMessage === "Average GPU Load text") {
      setIsGpuLoad(loadStatus);
    }
    if (loadMessage === "Average Network Load text") {
      setIsNetworkLoad(loadStatus);
    }
    if (loadMessage === "Average GPU Load design") {
      setIsGpuLoad(loadStatus);
    }
    if (loadMessage === "Average Network Load design") {
      setIsNetworkLoad(loadStatus);
    }
    if (loadMessage === "Average GPU Load image") {
      setIsGpuLoad(loadStatus);
    }
    if (loadMessage === "Average Network Load image") {
      setIsNetworkLoad(loadStatus);
    }
    if (loadMessage === "Average GPU Load upscale") {
      setIsGpuLoad(loadStatus);
    }
    if (loadMessage === "Average Network Load upscale") {
      setIsNetworkLoad(loadStatus);
    }
    if (loadMessage === "Average GPU Load upscale_feed") {
      setIsGpuLoad(loadStatus);
    }
    if (loadMessage === "Average Network Load upscale_feed") {
      setIsNetworkLoad(loadStatus);
    }
    // if (id !== undefined && status === "started python script") {
    //   setIsGenerateIdWeb((prevId) => [...prevId, id]);
    // }
    setIsMessageId(id);
    setIsMessageStatus(status);
    setSocketTimer(isTimer);
    setSocketWorkflow(isWorkflow);
    if (status === "started python script" && pId === lastPart) {
      // startTimer(isTimer);
      const commaSeparatedValues = {
        id,
        status,
        boolean,
        singleCard,
        isTimer,
        imageCount,
        isBrockenImages,
        queue: 0,
        myQueue: 0,
      };
      setIsSetMessages((prevMessages) => [
        ...prevMessages,
        commaSeparatedValues,
      ]);
      scrollToBottom();
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }

    //what does negative text to?
    if (status === "started python script") {
      setIsTargetId(id);
    }

    if (status === "Generation Failed" && id) {
      setErrorMessage(status);
      setErrorId(id);
    }

    if (status === "API Complete" && id) {
      const row = isSetMessages?.find((set) => set?.id === id);
      if (!row?.isBrockenImages) {
        setTargetId(id);
        const indexToRemove = isSetMessages?.findIndex(
          (item) => item.id === targetId
        );

        if (indexToRemove !== -1) {
          const updatedMessages = isSetMessages?.filter(
            (_, index) => index !== indexToRemove
          );
          setIsSetMessages(updatedMessages);
          setTargetId("");
        }
        if (apiCount > 0) {
          setMaxCount(apiCount - 1);
        }
        SetImageGenerated(true);
        SetLatestImageGeneratedName(isWorkflow);
        if (id) {
          CallAPICompletion(id);
        }
      } else {
        setTimeout(() => {
          setTargetId(id);
          const indexToRemove = isSetMessages?.findIndex(
            (item) => item.id === targetId
          );

          if (indexToRemove !== -1) {
            const updatedMessages = isSetMessages?.filter(
              (_, index) => index !== indexToRemove
            );
            setIsSetMessages(updatedMessages);
            setTargetId("");
          }
          if (apiCount > 0) {
            setMaxCount(apiCount - 1);
          }
          SetImageGenerated(true);
          SetLatestImageGeneratedName(isWorkflow);
          if (id) {
            CallAPICompletion(id);
          }
        }, 10000);
      }
    }
  };

  useEffect(() => {
    setIsRedirect(isSetMessages);
    const initialTimers = isSetMessages.map((val) => val.isTimer);
    setTimers(initialTimers);
    const intervalIds = isSetMessages.map((val, index) => {
      if (val.status === "generating") {
        return setInterval(() => {
          setTimers((prevTimers) => {
            const updatedTimers = [...prevTimers];
            updatedTimers[index] -= 1;
            setIsSetMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[index] = {
                ...updatedMessages[index],
                isTimer: updatedTimers[index],
              };
              return updatedMessages;
            });
            return updatedTimers;
          });
        }, 1000);
      }
      return null;
    });

    return () => {
      intervalIds.forEach((intervalId) => clearInterval(intervalId));
    };
  }, [isSetMessages]);

  const CallAPICompletion = async (generationId) => {
    const data = localStorage.getItem("language");
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const payload = {
      projectId: Number(params.id),
      chinese: data === "ch" ? true : false,
      desiredGenerationId: generationId,
      teamId: teamId,
    };
    if (payload) {
      GetOneRecordApi((res) => {
        if (
          res?.status === 200 &&
          res?.data?.projectGenerationModel.length !== 0
        ) {
          setGeneratedImages(res?.data?.projectGenerationModel);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const reloadFeedGetCurrenUserAllGenerations = () => {
    const reloadPageIndex = 0;
    setIsLoader(false);
    const data = localStorage.getItem("language");
    // const baseUrl =
    //   isExample === false
    //     ? `${BaseURL.MONGO_DB}/CrudRouters/allRecords`
    //     : `${BaseURL.MONGO_DB}/CrudRouters/allExampleRecords`;
    const baseUrl = `${BaseURL.MONGO_DB}/CrudRouters/allRecords`;
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const payload = {
      projectId: Number(params.id),
      chinese: data === "ch" ? true : false,
      pageIndex: reloadPageIndex,
      pageSize: isPageSize,
      teamId: teamId,
    };
    if (payload) {
      setIsLoader(true);
      const token = localStorage.getItem("token");
      fetch(`${baseUrl}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          authorization: "Bearer " + [token],
        },
      })
        .then((response) => {
          if (response.status === 403) {
            updateSocketData({
              isExitPopup: true,
            });
            setIsLoader(false);
            return;
          }
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((res) => {
          if (res.status === 200) {
            if (res?.data?.length !== 0) {
              if (
                res?.data?.projectModel.userId !==
                res?.data?.projectModel.currentUserId
              ) {
                localStorage.setItem("loginUser", false);
              } else {
                localStorage.setItem("loginUser", true);
              }
              if (
                res?.status === 404 &&
                res?.message === "Project is deleted"
              ) {
                alert("This project is deleted");
                window.location.href = "/projects";
              }
              const newImages = res?.projectMedias || [];
              if (newImages.length === 0) {
                setAllImagesFetched(true);
              }
              setIsGeneratedAllImages(res?.data?.projectGenerationModel);
              setIsLoader(false);
              setIsReloadFeed(false);
              setIsPageIndex(0);
            }
          } else if (res.status === 202) {
            setIsExitTeam(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (typeof error !== "object" || !error.message) {
            handleClosePopupGlobal();
            setGlobalErrorMessage("An unknown error occurred");
          } else {
            setGlobalErrorMessage(error.message);
          }
          setIsLoader(false);
        });
    }
  };

  useEffect(() => {
    if (generatedAllImages?.length === 0) {
      // needed to trigger pagination, event listener to scrolling top
      setFirstLoad(true);
    } else {
      setFirstLoad(false);
    }

    if (isReloadFeed && generatedAllImages?.length === 0) {
      reloadFeedGetCurrenUserAllGenerations();
    }
  }, [generatedAllImages]);

  useEffect(() => {
    //to set the action of scrolling down, initialize for first load
    if (firstLoad) {
      setIsPageIndex(0);
    }

    if (
      generatedAllImages?.length <= 12 &&
      allImagesFetched &&
      isPageIndex === 0
    ) {
      // only on first loading, or on reload feed
      scrollToBottom();
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  }, [generatedAllImages, allImagesFetched, isPageIndex]);

  useEffect(() => {
    // for adding getonerecord to the list
    if (generatedImages?.length !== 0) {
      pushArrayList(generatedImages);
    }
  }, [generatedImages]);

  const pushArrayList = (generatedImages) => {
    for (let i = 0; i < generatedImages.length; i++) {
      const newGeneration = generatedImages[i];
      const generationId = newGeneration.generationId;

      const isGenerationAlreadyPresent = generatedAllImages.some(
        (existingGeneration) => {
          return existingGeneration.generationId === generationId;
        }
      );

      if (!isGenerationAlreadyPresent) {
        generatedAllImages.push(newGeneration);
      }
    }

    setGeneratedAllImages([...generatedAllImages]);
  };

  useEffect(() => {
    if (isGpuLoad === "Heavy Load") {
      setIsGpuLoadAlert(true);
    } else {
      setIsGpuLoadAlert(false);
    }
  }, [isGpuLoad]);

  useEffect(() => {
    if (isNetworkLoad === "Heavy Load") {
      setIsNetworkLoadAlert(true);
    } else {
      setIsNetworkLoadAlert(false);
    }
  }, [isNetworkLoad]);

  useEffect(() => {
    if (isMessageStatus === "started to upload") {
      const existingIndex = isSetMessages?.findIndex(
        (item) => item.id === isMessageId
      );
      if (existingIndex !== -1) {
        if (timer > 0) {
          const updatedMessages = [...isSetMessages];
          setIsSetMessages(updatedMessages);
        } else {
          const updatedMessages = [...isSetMessages];
          setIsSetMessages(updatedMessages);
          setIsTargetId("");
        }
        const updatedMessages = [...isSetMessages];
        updatedMessages[existingIndex].status = isMessageStatus;
        setIsSetMessages(updatedMessages);
      }
    }

    if (isMessageStatus === "queue") {
      const queue = isParts[2]?.split(":")[1]?.trim();
      const myQueue = isParts[3]?.split(":")[1]?.trim();
      const existingIndex = isSetMessages?.findIndex(
        (item) => item.id === isMessageId
      );
      if (existingIndex !== -1) {
        const updatedMessages = [...isSetMessages];
        updatedMessages[existingIndex].status = "queuing";
        updatedMessages[existingIndex].queue = queue;
        updatedMessages[existingIndex].myQueue = myQueue;
        setIsSetMessages(updatedMessages);
      }
    }

    if (isMessageStatus === "sending now") {
      const existingIndex = isSetMessages?.findIndex(
        (item) => item.id === isMessageId
      );
      if (existingIndex !== -1) {
        const updatedMessages = [...isSetMessages];
        updatedMessages[existingIndex].status = "generating";
        setIsSetMessages(updatedMessages);
      }
    }

    if (socketWorkflow === "upscale") {
      const existingIndex = isSetMessages?.findIndex(
        (item) => item.id === isMessageId
      );
      if (existingIndex !== -1) {
        const updatedMessages = [...isSetMessages];
        updatedMessages[existingIndex].singleCard = true;
        setIsSetMessages(updatedMessages);
      }
    }
    if (socketWorkflow === "upscale_feed") {
      const existingIndex = isSetMessages?.findIndex(
        (item) => item.id === isMessageId
      );
      if (existingIndex !== -1) {
        const updatedMessages = [...isSetMessages];
        updatedMessages[existingIndex].singleCard = true;
        setIsSetMessages(updatedMessages);
      }
    }

    if (errorMessage === "Generation Failed" && errorId) {
      const indexToRemove = isSetMessages?.findIndex(
        (item) => item.id === errorId
      );

      if (indexToRemove !== -1) {
        const updatedMessages = isSetMessages?.filter(
          (_, index) => index !== indexToRemove
        );
        setIsSetMessages(updatedMessages);
        setErrorId(null);
        setErrorMessage("");
      }
    }

    if (targetId) {
      const indexToRemove = isSetMessages?.findIndex(
        (item) => item.id === targetId
      );

      if (indexToRemove !== -1) {
        const updatedMessages = isSetMessages?.filter(
          (_, index) => index !== indexToRemove
        );
        setIsSetMessages(updatedMessages);
        setTargetId("");
      }
    }
  }, [targetId, isMessageStatus, isMessageId, socketTimer, socketWorkflow]);

  const handleScroll = () => {
    const targetElement = targetRef.current;
    if (targetElement) {
      const scrollHeight = targetElement.scrollHeight;
      const scrollTop = targetElement.scrollTop;
      const clientHeight = targetElement.clientHeight;

      const tolerance = 1;
      const isScrolledToBottom =
        scrollTop + clientHeight >= scrollHeight - tolerance;

      setShowButton(!isScrolledToBottom);
    }
  };

  const handleMaxButtonClick = () => {
    if (isPrivilagesDetails?.concurrent >= 4) {
      setShowMaxAlert1(true);
    } else {
      //less then 4
      if (isActiveTeam?.roleId === 1 || isActiveTeam?.teamId === 0.1) {
        // creator or solo mode
        setShowMaxAlert(true);
      } else {
        setSubscriptionLimit(true);
      }
    }
  };

  useEffect(() => {
    if (showMaxAlert1 === true) {
      setTimeout(() => {
        setShowMaxAlert1(false);
      }, 10000);
    }
  }, [showMaxAlert1]);

  useEffect(() => {
    if (timer < 0) {
      if (isTargetId) {
        const existingIndex = isSetMessages.findIndex(
          (item) => item.id === isTargetId
        );
        if (existingIndex !== -1) {
          const updatedMessages = [...isSetMessages];
          updatedMessages[existingIndex].negativeText = true;
          setIsSetMessages(updatedMessages);
        }
      }
    }
  }, [
    isSetMessages,
    isMessageStatus,
    isMessageId,
    socketTimer,
    socketWorkflow,
    timer,
    isTargetId,
  ]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(!isGptActivePopup);
  };

  const handleSubscription = () => {
    handleShowSubscription();
    setIsGptActivePopup(false);
  };

  const handleViewPlans = () => {
    handleShowSubscription();
    handleCloseLimit();
  };

  const handleCloseLimit = () => {
    setIsLimitPopup(!isLimitPoup);
  };

  const handleActiveLearn = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY,
    };
    if (id === activeLearnBool?.id) {
      const newData = {
        id: id,
        boolean: !activeLearnBool?.boolean,
        position: position,
      };
      setActiveLearnBool(newData);
    } else if (id !== activeLearnBool?.id) {
      const newData1 = {
        id: id,
        boolean: true,
        position: position,
      };
      setActiveLearnBool(newData1);
    }
  };

  const handleActiveLearnClose = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY,
    };
    if (id === activeLearnBool?.id) {
      const newData = {
        id: id,
        boolean: false,
        position: position,
      };
      setActiveLearnBool(newData);
    }
  };

  useEffect(() => {
    if (uploadError !== null && uploadError !== undefined) {
      if (uploadError?.val === true) {
        setGlobalError(true);
        setGlobalErrorMessage(uploadError?.error);
      }
    }
  }, [uploadError]);

  const handleClosePopupGlobal = () => {
    setGlobalError(!globalError);
    let check = JSON.parse(localStorage.getItem(`upload_error_${lastPart}`));
    if (check !== null && check !== undefined) {
      if (check.val !== undefined && check.val !== null && check.val === true) {
        check.val = false;
      }
    }
    localStorage.setItem(`upload_error_${lastPart}`, check);
  };

  const scrollToBottom = () => {
    const targetElement = targetRef.current;
    if (targetElement) {
      const scrollOptions = {
        top: targetElement.scrollHeight,
        behavior: "smooth",
      };
      targetElement.scrollTo(scrollOptions);
    }
  };

  const handleClick = () => {
    scrollToBottom();
  };

  const getCurrentProjectDetails = async () => {
    if (!hasFetchedRef.current) {
      hasFetchedRef.current = true;
      setHasFetchedProjectDetails(true);
      const data = localStorage.getItem("language");
      // const baseUrl =
      //   isExample === false
      //     ? `${BaseURL.MONGO_DB}/CrudRouters/allRecords`
      //     : `${BaseURL.MONGO_DB}/CrudRouters/allExampleRecords`;
      const baseUrl = `${BaseURL.MONGO_DB}/CrudRouters/allRecords`;
      const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
      const payload = {
        projectId: Number(params.id),
        chinese: data === "ch" ? true : false,
        pageIndex: isPageIndex,
        pageSize: isPageSize,
        teamId: teamId,
      };
      if (payload) {
        if (generatedAllImages?.length === 0) {
          setIsLoader(true);
          const token = localStorage.getItem("token");
          fetch(`${baseUrl}`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
              accept: "text/plain",
              authorization: "Bearer " + [token],
            },
          })
            .then((response) => {
              if (response.status === 403) {
                updateSocketData({
                  isExitPopup: true,
                });
                setIsLoader(false);
                return;
              }
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((res) => {
              if (res.status === 200) {
                if (res?.data?.length !== 0) {
                  if (
                    res?.data?.projectModel.userId !==
                    res?.data?.projectModel.currentUserId
                  ) {
                    localStorage.setItem("loginUser", false);
                  } else {
                    localStorage.setItem("loginUser", true);
                  }
                  if (
                    res?.status === 404 &&
                    res?.message === "Project is deleted"
                  ) {
                    alert("This project is deleted");
                    window.location.href = "/projects";
                  }
                  const newImages = res?.projectMedias || [];
                  if (newImages.length === 0) {
                    setAllImagesFetched(true);
                  }
                  setProjectName(res?.data?.projectModel?.projectName);
                  setIsNewProjectName(res?.data?.projectModel?.projectName);
                  setIsGeneratedAllImages(res?.data?.projectGenerationModel);
                  setIsLoader(false);
                }
              } else if (res.status === 202) {
                setIsExitTeam(true);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              if (typeof error !== "object" || !error.message) {
                handleClosePopupGlobal();
                setGlobalErrorMessage("An unknown error occurred");
              } else {
                setGlobalErrorMessage(error.message);
              }
              setIsLoader(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    let isLoading = false;
    const getCurrentProjectDetails1 = () => {
      if (allImagesFetched && !isLoading) {
        isLoading = true;
        const data = localStorage.getItem("language");
        const baseUrl = `${BaseURL.MONGO_DB}/CrudRouters/allRecords`;
        const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
        const payload = {
          projectId: Number(params.id),
          chinese: data === "ch" ? true : false,
          pageIndex:
            isPageIndex === 0 && generatedAllImages?.length === 12
              ? 1
              : isPageIndex,
          pageSize: isPageSize,
          teamId: teamId,
        };
        if (payload) {
          const token = localStorage.getItem("token");
          fetch(`${baseUrl}`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
              accept: "text/plain",
              authorization: "Bearer " + [token],
            },
          })
            .then((response) => {
              if (response.status === 403) {
                updateSocketData({
                  isExitPopup: true,
                });
                setIsLoader(false);
                return;
              }
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((res) => {
              if (res?.message === "Invalid access token") {
                updateSocketData({
                  isExitPopup: true,
                });
              } else if (res?.data?.length !== 0) {
                const newImages = res?.data?.projectGenerationModel || [];
                if (newImages.length === 0) {
                  setAllImagesFetched(true);
                }
                setProjectName(res?.data?.projectModel?.projectName);
                setIsNewProjectName(res?.data?.projectModel?.projectName);
                setIsGeneratedAllImages(res?.data?.projectGenerationModel);
                isLoading = false;

                const targetElement = targetRef.current;
                const isNearTop = targetElement.scrollTop === 0;

                if (isNearTop && isPageIndex === 0) {
                  setIsPageIndex(2);
                } else {
                  setIsPageIndex((prevIndex) => prevIndex + 1);
                }
              }
            })
            .catch((error) => {
              handleClosePopupGlobal();
              setGlobalErrorMessage(error);
              console.error("Error:", error);
            });
        }
      }
    };

    const handleScrollTop = () => {
      const targetElement = targetRef.current;
      const isNearTop = targetElement.scrollTop === 0;
      if (isNearTop && !isLoading) {
        getCurrentProjectDetails1();
      }
    };
    if (!firstLoad) {
      const targetElement = targetRef.current;
      if (targetElement) {
        targetElement.addEventListener("scroll", handleScrollTop);
      }
      return () => {
        if (targetElement) {
          targetElement.removeEventListener("scroll", handleScrollTop);
        }
      };
    }
  }, [isPageIndex, isPageSize, firstLoad]);

  useEffect(() => {
    if (isGeneratedAllImages?.length !== 0) {
      const uniqueGenerationIds = new Set(
        generatedAllImages?.map((image) => image?.generationId)
      );

      const uniqueImages = isGeneratedAllImages?.filter((newImage) => {
        return !uniqueGenerationIds.has(newImage?.generationId);
      });

      if (uniqueImages?.length > 0) {
        setGeneratedAllImages((prevImages) => [...uniqueImages, ...prevImages]);

        const targetElement = targetRef.current;
        if (targetElement && generatedAllImages !== 12) {
          const newHeight = targetElement.scrollHeight - prevScrollHeight;
          setPrevScrollHeight(targetElement.scrollHeight);
          const scrollOptions = {
            top: newHeight,
          };
          targetElement.scrollTo(scrollOptions);
        }
      }
    }

    if (generatedAllImages?.length <= 0 && isGeneratedAllImages?.length !== 0) {
      // we really need to start commenting !!!!
      setGeneratedAllImages(isGeneratedAllImages);
    }
  }, [isGeneratedAllImages, generatedAllImages]);

  useEffect(() => {
    if (atmosphereRefLocal?.[0]?.uploadedBaseImageUrl) {
      if (
        atmosphereRefLocal?.[0]?.feedDesignBase64 === null ||
        atmosphereRefLocal?.[0]?.feedDesignBase64 === undefined
      ) {
        setIsReferenceImg(true);
      } else {
        setIsReferenceImg(false);
      }
    } else {
      setIsReferenceImg(false);
    }
  }, [atmosphereRefLocal]);

  useEffect(() => {
    if (isActiveToolset === 1) {
      if (
        designLocalBase?.feedDesignBase64 === undefined ||
        designLocalBase?.feedDesignBase64 === null
      ) {
        setIsDesignToolsetBaseImg(true);
      } else {
        setIsDesignToolsetBaseImg(false);
      }
    }
  }, [designLocalBase, isActiveToolset]);

  useEffect(() => {
    if (
      editToolsetData?.maskImageBase64 === undefined ||
      editToolsetData?.maskImageBase64 === null
    ) {
      setIsImageToolsetMaskImg(true);
    } else {
      setIsImageToolsetMaskImg(false);
    }
  }, [editToolsetData, isActiveToolset]);

  useEffect(() => {
    if (isActiveToolset === 2) {
      if (
        editToolsetData?.feedImageBase64 === undefined ||
        editToolsetData?.feedImageBase64 === null
      ) {
        setIsImageToolsetBaseImg(true);
      } else {
        setIsImageToolsetBaseImg(false);
      }
    }
  }, [editToolsetData, isActiveToolset]);

  useEffect(() => {
    const selection = localStorage.getItem(`selection_${lastPart}`);
    if (isActiveToolset === 2 && selection == "2") {
      if (
        editToolsetData?.cnImageBase64 === undefined ||
        editToolsetData?.cnImageBase64 === null
      ) {
        setIsImageToolsetCNImg(true);
      } else {
        setIsImageToolsetCNImg(false);
      }
    }
  }, [editToolsetData, isActiveToolset]);

  useEffect(() => {
    if (isActiveToolset === 3) {
      if (
        upscaleLocalBase?.feedUpBase64 === undefined ||
        upscaleLocalBase?.feedUpBase64 === null
      ) {
        setIsUpscaleToolsetBaseImg(true);
      } else {
        setIsUpscaleToolsetBaseImg(false);
      }
    }
  }, [upscaleLocalBase, isActiveToolset]);

  useEffect(() => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const concurrent = isPrivilagesDetails?.concurrent ?? 0;
    if (upscaleToolsetData?.isUpscaleFeedApi === true) {
      if (maxCount >= concurrent || maxCount >= 4) {
        handleMaxButtonClick();
      } else {
        const lang = localStorage.getItem("language");
        setIsButtonDisabled(true);
        setShowBrockenImages(false);
        setShowSingleCard(true);
        setIsShowLoadingCard(true);
        const payloadUpscale = {
          projectId: parseInt(params.id),
          projectMediaId: upscaleToolsetData?.isMediaId,
          baseImage:
            upscaleToolsetData?.feedUpBase64?.path ||
            upscaleToolsetData?.feedUpBase64,
          height: upscaleToolsetData?.feedUpBase64?.height || null,
          width: upscaleToolsetData?.feedUpBase64?.width || null,
          chinese: lang === "ch" ? true : false,
          teamId: teamId,
        };
        if (payloadUpscale) {
          openUserSocket();
          openWebSocket();
          updateUpscaleToolsetData({
            isUpscaleFeedApi: false,
          });
          GenerateWorkFlowUpscaleFeed(
            (res) => handleApiResponse(res),
            payloadUpscale
          );
        }
      }
    }
  }, [upscaleToolsetData]);

  const ErrorMessagesEN = {
    200: "Empty response",
    201: "Missing Parameters, please refresh the page and add all inputs",
    204: "Request Timeout, the server timed out waiting for the request",
    211: "Current membership does not support the use of teams, please upgrade or nagivate to 【my space】",
    212: "Account limit exceeded membership allowance, all members' generations are limited",
    400: "Bad Request, please check your request",
    401: "Unauthorized, please authenticate your request",
    403: "Forbidden, access to the requested resource is forbidden",
    404: "Not Found, the requested resource was not found",
    405: "Method Not Allowed, the requested HTTP method is not allowed",
    408: "Request Timeout, the server timed out waiting for the request",
    500: "Internal Server Error, an internal server error occurred",
    502: "Bad Gateway, We are updating AIRI",
    503: "Service Unavailable, the server is currently unable to handle the request",
    504: "Gateway Timeout, the server timed out while waiting for a response from an upstream server",
    default: "We are updating AIRI, please come back later",
  };

  const ErrorMessagesCN = {
    200: "返回空白, 什么都没有收到",
    201: "参数缺失，请刷新后重新载入所有选项",
    204: "请求超时, 服务器等待请求的时间过长",
    211: "当前套餐不支持团队和隐私模式生成，请升级会员或去【我的空间】",
    212: "团队成员超出套餐允许人数，所有成员生成受限",
    400: "错误请求, 请检查您的请求",
    401: "未授权, 请对您的请求进行认证",
    403: "禁止, 禁止访问所请求的资源",
    404: "未找到, 未找到所请求的资源",
    405: "方法不允许, 不允许使用请求的HTTP方法",
    408: "请求超时, 服务器等待请求的时间过长",
    500: "内部服务器错误, 发生了内部服务器错误",
    502: "错误的网关, 我们正在更新AIRI",
    503: "服务不可用, 服务器目前无法处理请求",
    504: "网关超时, 服务器在等待来自上游服务器的响应时超时",
    1: "API出错, 请重试",
    default: "我们正在更新AIRI, 请稍后再来",
  };

  const handleApiResponse = (res) => {
    let { data, message, status } = res;
    if (
      status === 200 &&
      res?.data[0]?.projectMedias?.length !== 0 &&
      res?.data[0]?.projectMedias?.length !== undefined
    ) {
      // setGeneratedImages(data);
      setShowBrockenImages(false);
      setIsShowLoadingCard(false);
      setIsButtonDisabled(false);
      setIsLimitPopup(false);
      setIsExitTeam(false);
    } else if (status === 203) {
      setShowBrockenImages(false);
      setIsShowLoadingCard(false);
      setIsButtonDisabled(false);
      setIsLimitPopup(true);
      setIsExitTeam(false);
    } else if (res.message === "API rate limit exceeded") {
      setShowBrockenImages(false);
      setIsShowLoadingCard(false);
      setIsButtonDisabled(false);
      setIsLimitPopup(false);
      setIsExitTeam(false);
    } else if (status === 213) {
      setShowBrockenImages(false);
      setIsShowLoadingCard(false);
      setIsButtonDisabled(false);
      setIsLimitPopup(false);
      setIsExitTeam(true);
    } else {
      let ErrorMessages =
        isActiveLanguage === "en" ? ErrorMessagesEN : ErrorMessagesCN;
      let add_message = ErrorMessages[status] || ErrorMessages.default;
      if (res instanceof Error) {
        add_message = ErrorMessages[1];
        status = "JS Err";
      }
      setShowBrockenImages(true);
      setIsButtonDisabled(false);
      setIsShowLoadingCard(false);
      setIsLimitPopup(false);
      setIsExitTeam(false);
      let alertMessage = (status || "502") + ": " + add_message;
      setGlobalErrorMessage(alertMessage);
      if (status !== 206) {
        handleClosePopupGlobal();
      }
      // setIsSetMessages([]);
    }
    if (res?.message === "Invalid access token") {
      updateSocketData({
        isExitPopup: true,
      });
    }
  };

  const openWebSocket = () => {
    if (!isWebSocket || isWebSocket.readyState === WebSocket.CLOSED) {
      const token = localStorage.getItem("token");
      const teamId = isActiveTeam?.teamId === 0.1 ? 0 : isActiveTeam?.teamId;
      const socket = new WebSocket(
        `wss://${AIRI_BASE_URL_WS}/websocket?teamId=${teamId}`,
        ["Bearer", token]
      );

      socket.onopen = () => {
        // console.log("WebSocket connected");
        setReceivedMessages([]); // Clear received messages when opening the socket
      };

      socket.onmessage = (event) => {
        const message = event.data;
        const regex = /API Count: (\d+)/;
        const match = message.match(regex);
        if (match && match[1]) {
          maxCount = parseInt(match[1]);
          setMaxCount(maxCount);
        }
        getMessage(message);
      };

      socket.onclose = () => {
        // console.log("WebSocket connection closed");
      };

      setIsWebSocket(socket);
    }
  };

  // useEffect(() => {
  //   if (!isWebSocket || isWebSocket.readyState === WebSocket.CLOSED) {
  //     openWebSocket();
  //   }
  // }, [isWebSocket]);

  const buildTextPayload = (
    dlData,
    tiresData,
    toolsetLocal,
    cameraData,
    atmosphere,
    imageCountData,
    promptLocalData,
    privateModel,
    referenceImage,
    templateData,
    graphicData
  ) => {
    const lang = localStorage.getItem("language");
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    return {
      projectId: parseInt(params.id),
      designLibraryName: templateData?.isTemplate
        ? dlData?.designLibraryName
        : "No Style",
      designLibraryId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId
        : 99,
      firstTierName: templateData?.isTemplate
        ? tiresData?.firstTierName
        : "No Style",
      firstTierId: templateData?.isTemplate ? tiresData?.isFirstTierId : 9999,
      secondTierName: templateData?.isTemplate
        ? tiresData?.secondTierName
        : "No Style",
      secondTierId: templateData?.isTemplate ? tiresData?.isSecondTierId : 9999,
      // thirdTierName: tiresData?.thirdTierName,
      // thirdTierId: tiresData?.isThirdTierId,
      styleId: templateData?.isTemplate ? tiresData?.styleId || 9999 : 9999,
      orientation: toolsetLocal?.orientation || 0,
      imageRatio: toolsetLocal?.imageRatio || 0,
      cameraViewName: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId !== 30
          ? t(cameraData?.cameraViewName)
          : "Interior"
        : "No Camera",
      cameraViewId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 30
          ? 1000
          : dlData?.prevDesignLibraryId === 99
          ? 9999
          : cameraData?.cameraViewId || 1
        : 9999,
      atmosphereId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 99
          ? 99
          : atmosphere?.defaultSelectedAtmosphereId || 1
        : 99,
      chinese: lang === "ch" ? true : false,
      teamId: teamId,
      imageCount: imageCountData,
      additionalPrompt: templateData?.isPrompt
        ? promptLocalData?.prompt || ""
        : "",
      additionalNegativePrompt: templateData?.isPrompt
        ? promptLocalData?.negativePrompt
        : "",
      privateModel: templateData?.isPrivate ? privateModel : "",
      referenceImage: templateData?.isAtmosphere ? referenceImage : [],
      graphicStyleId: templateData?.isTemplate ? graphicData?.graphicId : 9999,
      seed: -1,
    };
  };

  const buildDesignPayload = (
    dlData,
    tiresData,
    toolsetLocal,
    cameraData,
    atmosphere,
    renderToolsetData,
    imageTypeData,
    imageCountData,
    promptLocalData,
    privateModel,
    referenceImage,
    templateData,
    graphicData,
    preserveLocal,
    resolutionLocal
  ) => {
    const lang = localStorage.getItem("language");
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const resolutionSelected = resolutionLocal?.valid
      ? resolutionLocal?.id === 1
        ? true
        : false
      : true;
    return {
      projectId: parseInt(params.id),
      designLibraryName: templateData?.isTemplate
        ? dlData?.designLibraryName
        : "No Style",
      designLibraryId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId
        : 99,
      firstTierName: templateData?.isTemplate
        ? tiresData?.firstTierName
        : "No Style",
      firstTierId: templateData?.isTemplate ? tiresData?.isFirstTierId : 9999,
      secondTierName: templateData?.isTemplate
        ? tiresData?.secondTierName
        : "No Style",
      secondTierId: templateData?.isTemplate ? tiresData?.isSecondTierId : 9999,
      // thirdTierName: tiresData?.thirdTierName,
      // thirdTierId: tiresData?.isThirdTierId,
      styleId: templateData?.isTemplate ? tiresData?.styleId || 9999 : 9999,
      cameraViewName: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId !== 30
          ? t(cameraData?.cameraViewName)
          : "Interior"
        : "No Camera",
      cameraViewId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 30
          ? 1000
          : dlData?.prevDesignLibraryId === 99
          ? 9999
          : cameraData?.cameraViewId || 1
        : 9999,
      baseImage: designLocalBase?.feedDesignBase64?.path ||
          designLocalBase?.feedDesignBase64,
      height:designLocalBase?.feedDesignBase64?.height || null,
      width: designLocalBase?.feedDesignBase64?.width || null,
      imageType:
        imageTypeData?.id === 1 ? "Control line" : "Control depth" || "general",
      inputFidelityLevel: preserveLocal?.valid
        ? toolsetLocal?.preserveMaterial ?? 1
        : 0,
      controlLevel: toolsetLocal?.renderFidelityLevel || 5,
      atmosphereId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 99
          ? 99
          : atmosphere?.defaultSelectedAtmosphereId || 1
        : 99,
      chinese: lang === "ch" ? true : false,
      teamId: teamId,
      imageCount: imageCountData,
      additionalPrompt: templateData?.isPrompt
        ? promptLocalData?.prompt || ""
        : "",
      additionalNegativePrompt: templateData?.isPrompt
        ? promptLocalData?.negativePrompt
        : "",
      privateModel: templateData?.isPrivate ? privateModel : "",
      referenceImage: templateData?.isAtmosphere ? referenceImage : [],
      graphicStyleId: templateData?.isTemplate ? graphicData?.graphicId : 9999,
      seed: -1,
      megapixels: resolutionSelected ? 2.25 : 4,
    };
  };

  const buildImagePayload = (
    dlData,
    tiresData,
    toolsetLocal,
    editToolsetData,
    imageTypeData,
    imageCountData,
    promptLocalData,
    cameraData,
    atmosphere,
    privateModel,
    selection,
    referenceImage,
    templateData,
    graphicData
  ) => {
    const lang = localStorage.getItem("language");
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    return {
      projectId: parseInt(params.id),
      designLibraryName: templateData?.isTemplate
        ? dlData?.designLibraryName
        : "Graphic",
      designLibraryId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId
        : 99,
      firstTierName: templateData?.isTemplate
        ? tiresData?.firstTierName
        : "No Style",
      firstTierId: templateData?.isTemplate ? tiresData?.isFirstTierId : 9999,
      secondTierName: templateData?.isTemplate
        ? tiresData?.secondTierName
        : "No Style",
      secondTierId: templateData?.isTemplate ? tiresData?.isSecondTierId : 9999,
      // thirdTierName: tiresData?.thirdTierName,
      // thirdTierId: tiresData?.isThirdTierId,
      styleId: templateData?.isTemplate ? tiresData?.styleId || 9999 : 9999,
      cameraViewName: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId !== 30
          ? t(cameraData?.cameraViewName)
          : "Interior"
        : "No Camera",
      cameraViewId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 30
          ? 1000
          : dlData?.prevDesignLibraryId === 99
          ? 9999
          : cameraData?.cameraViewId || 1
        : 9999,
      atmosphereId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 99
          ? 99
          : atmosphere?.defaultSelectedAtmosphereId || 1
        : 99,
      baseImage:
        editToolsetData?.feedImageBase64?.path ||
        editToolsetData?.feedImageBase64,
      height: editToolsetData?.feedImageBase64?.height || null,
      width: editToolsetData?.feedImageBase64?.width || null,
      maskImage: editToolsetData?.maskImageBase64?.path,
      imageType:
        selection == "2"
          ? imageTypeData?.id === 1
            ? "Control line"
            : "Control depth" || "general"
          : "general",
      originalImage:
        editToolsetData?.isFromDesignActivated === true && selection == "2"
          ? editToolsetData?.cnImageBase64?.path
          : undefined,
      initialCNImage:
        editToolsetData?.isFromDesignActivated === true && selection == "2"
          ? editToolsetData?.originalCnImageBase64?.path
          : undefined,
      inputFidelityLevel: toolsetLocal?.editFidelityLevel,
      controlLevel: selection == "2" ? toolsetLocal?.maskFidelityLevel : 0,
      chinese: lang === "ch" ? true : false,
      teamId: teamId,
      imageCount: imageCountData,
      additionalPrompt: templateData?.isPrompt
        ? promptLocalData?.prompt || ""
        : "",
      additionalNegativePrompt: templateData?.isPrompt
        ? promptLocalData?.negativePrompt
        : "",
      privateModel: templateData?.isPrivate ? privateModel : "",
      referenceImage: templateData?.isAtmosphere ? referenceImage : [],
      graphicStyleId: templateData?.isTemplate ? graphicData?.graphicId : 9999,
      // subToolset: selection === "1" ? "Inpaint" : selection === "2" ? "Ref Shape" : "Eraser"
      seed: -1,
    };
  };

  const buildImageEraserPayload = (editToolsetData, imageCountData) => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    return {
      projectId: parseInt(params.id),
      baseImage:
        editToolsetData?.feedImageBase64?.path ||
        editToolsetData?.feedImageBase64,
      maskImage: editToolsetData?.maskImageBase64?.path,
      teamId: teamId,
      imageCount: imageCountData,
    };
  };

  const buildUpscalePayload = (upscaleToolsetData) => {
    const lang = localStorage.getItem("language");
    const upscaleLocalBase = JSON.parse(
      localStorage.getItem(`upscaleBaseimg_${lastPart}`)
    );
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    return {
      projectId: parseInt(params.id),
      baseImage:
        upscaleLocalBase?.feedUpBase64?.path || upscaleLocalBase?.feedUpBase64,
      height: upscaleLocalBase?.feedUpBase64?.height || null,
      width: upscaleLocalBase?.feedUpBase64?.width || null,
      chinese: lang === "ch" ? true : false,
      teamId: teamId,
    };
  };

  const buildUpscaleExtendPayload = (
    dlData,
    tiresData,
    toolsetLocal,
    editToolsetData,
    imageTypeData,
    imageCountData,
    promptLocalData,
    cameraData,
    atmosphere,
    privateModel,
    selection,
    referenceImage,
    templateData,
    graphicData,
    horizontal = true,
    extendValue
  ) => {
    const lang = localStorage.getItem("language");
    const upscaleLocalBase = JSON.parse(
      localStorage.getItem(`upscaleBaseimg_${lastPart}`)
    );
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    return {
      projectId: parseInt(params.id),
      designLibraryName: templateData?.isTemplate
        ? dlData?.designLibraryName
        : "Graphic",
      designLibraryId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId
        : 99,
      firstTierName: templateData?.isTemplate
        ? tiresData?.firstTierName
        : "No Style",
      firstTierId: templateData?.isTemplate ? tiresData?.isFirstTierId : 9999,
      secondTierName: templateData?.isTemplate
        ? tiresData?.secondTierName
        : "No Style",
      secondTierId: templateData?.isTemplate ? tiresData?.isSecondTierId : 9999,
      // thirdTierName: tiresData?.thirdTierName,
      // thirdTierId: tiresData?.isThirdTierId,
      styleId: templateData?.isTemplate ? tiresData?.styleId || 9999 : 9999,
      cameraViewName: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId !== 30
          ? t(cameraData?.cameraViewName)
          : "Interior"
        : "No Camera",
      cameraViewId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 30
          ? 1000
          : dlData?.prevDesignLibraryId === 99
          ? 9999
          : cameraData?.cameraViewId || 1
        : 9999,
      atmosphereId: templateData?.isTemplate
        ? dlData?.prevDesignLibraryId === 99
          ? 99
          : atmosphere?.defaultSelectedAtmosphereId || 1
        : 99,
      baseImage:
        upscaleLocalBase?.feedUpBase64?.path || upscaleLocalBase?.feedUpBase64,
      height: upscaleLocalBase?.feedUpBase64?.height || null,
      width: upscaleLocalBase?.feedUpBase64?.width || null,
      chinese: lang === "ch" ? true : false,
      imageCount: 1,
      imageType: "default",
      inputFidelityLevel: 0,
      additionalPrompt: templateData?.isPrompt
        ? promptLocalData?.prompt || ""
        : "",
      additionalNegativePrompt: templateData?.isPrompt
        ? promptLocalData?.negativePrompt
        : "",
      privateModel: templateData?.isPrivate ? privateModel : "",
      referenceImage: templateData?.isAtmosphere ? referenceImage : [],
      graphicStyleId: templateData?.isTemplate ? graphicData?.graphicId : 9999,
      seed: -1,
      teamId: teamId,
      verticalPercentage: !horizontal ? extendValue : 0,
      horizontalPercentage: horizontal ? extendValue : 0,
    };
  };

  const handleGenerate = () => {
    openUserSocket();
    openWebSocket();
    setIsButtonDisabled(true);
    setShowBrockenImages(false);
    setShowSingleCard(isActiveToolset === 3);
    setIsShowLoadingCard(true);

    const labels = {
      "-4": "Vertical +100%",
      "-3": "Vertical +80%",
      "-2": "Vertical +60%",
      "-1": "Vertical +40%",
      1: "Horizontal +40%",
      2: "Horizontal +60%",
      3: "Horizontal +80%",
      4: "Horizontal +100%",
    };
    const values = {
      "-4": 100,
      "-3": 80,
      "-2": 60,
      "-1": 40,
      1: 40,
      2: 60,
      3: 80,
      4: 100,
    };

    const dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
    const tiresData = JSON.parse(
      localStorage.getItem(`designLibraryData_${lastPart}`)
    );
    const toolsetLocal = JSON.parse(
      localStorage.getItem(`toolsetData_${lastPart}`)
    );
    const atmosphere = JSON.parse(
      localStorage.getItem(`atmosphereData_${lastPart}`)
    );
    const cameraData = JSON.parse(
      localStorage.getItem(`cameraData_${lastPart}`)
    );
    const imageTypeData = JSON.parse(
      localStorage.getItem(`imageTypeData_${lastPart}`)
    );
    const imageCountData = JSON.parse(
      localStorage.getItem(`imageCount_${lastPart}`)
    );
    const promptLocalData = JSON.parse(
      localStorage.getItem(`promptData_${lastPart}`)
    );
    const selection = localStorage.getItem(`selection_${lastPart}`);
    let privateModel = "";
    if (promptLocalData?.privateModel?.value === 1) {
      privateModel = "";
    } else {
      privateModel = `${promptLocalData?.privateModel?.value};${promptLocalData?.privateModel?.label};${promptLocalData?.privateModel?.modelNameAlias};${promptLocalData?.privateModelValue};${promptLocalData?.privateModel?.modelPrompt}`;
    }
    const atmosphereRefLocal = JSON.parse(
      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
    );
    const templateData = JSON.parse(
      localStorage.getItem(`isTemplate_${lastPart}`)
    );
    const graphicData = JSON.parse(
      localStorage.getItem(`graphicStyleData_${lastPart}`)
    );
    const preserveLocal = JSON.parse(
      localStorage.getItem(`preserve_${lastPart}`)
    );
    const localEnhanceOption = localStorage.getItem(
      `enhance-option_${lastPart}`
    );
    const upscaleTypeData = JSON.parse(
      localStorage.getItem(`upscaleTypeData_${lastPart}`)
    );
    const enhanceLocal = JSON.parse(
      localStorage.getItem(`enhanceData_${lastPart}`)
    );
    const resolutionLocal = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );
    const referenceImage =
      atmosphereRefLocal &&
      atmosphereRefLocal
        ?.filter((re) => re?.uploadedBaseImageUrl !== null)
        ?.map((re) => {
          return {
            url: re?.uploadedBaseImageUrl,
            weight: re?.refFidelityLevel,
          };
        });
    const horizontal =
      labels[enhanceLocal?.extendFidelityLevel]?.includes("Horizontal");
    const extendValue = values[enhanceLocal?.extendFidelityLevel];

    const payloadText = buildTextPayload(
      dlData,
      tiresData,
      toolsetLocal,
      cameraData,
      atmosphere,
      imageCountData,
      promptLocalData,
      privateModel,
      referenceImage,
      templateData,
      graphicData
    );
    const payloadDesign = buildDesignPayload(
      dlData,
      tiresData,
      toolsetLocal,
      cameraData,
      atmosphere,
      renderToolsetData,
      imageTypeData,
      imageCountData,
      promptLocalData,
      privateModel,
      referenceImage,
      templateData,
      graphicData,
      preserveLocal,
      resolutionLocal
    );
    const payloadImage = buildImagePayload(
      dlData,
      tiresData,
      toolsetLocal,
      editToolsetData,
      imageTypeData,
      imageCountData,
      promptLocalData,
      cameraData,
      atmosphere,
      privateModel,
      selection,
      referenceImage,
      templateData,
      graphicData
    );
    const payloadEraser = buildImageEraserPayload(
      editToolsetData,
      imageCountData
    );
    const payloadUpscale = buildUpscalePayload(upscaleToolsetData);
    const payloadUpscaleExtend = buildUpscaleExtendPayload(
      dlData,
      tiresData,
      toolsetLocal,
      editToolsetData,
      imageTypeData,
      imageCountData,
      promptLocalData,
      cameraData,
      atmosphere,
      privateModel,
      selection,
      referenceImage,
      templateData,
      graphicData,
      horizontal,
      extendValue
    );

    const handleFullFillAllRequirements = () => {
      setTemplateSelection(false);
      if (isActiveToolset === 0 && payloadText) {
        GenerateWorkFlowText((res) => handleApiResponse(res), payloadText);
      } else if (isActiveToolset === 1 && payloadDesign) {
        GenerateWorkFlowDesign((res) => handleApiResponse(res), payloadDesign);
      } else if (isActiveToolset === 2 && payloadImage) {
        if (payloadEraser && selection == "3") {
          GenerateWorkFlowEraser(
            (res) => handleApiResponse(res),
            payloadEraser
          );
        } else if (payloadImage) {
          GenerateWorkFlowImage((res) => handleApiResponse(res), payloadImage);
        }
      } else if (
        isActiveToolset === 3 &&
        (payloadUpscale || payloadUpscaleExtend)
      ) {
        if (localEnhanceOption === "1") {
          GenerateWorkFlowUpscaleExtend(
            (res) => handleApiResponse(res),
            payloadUpscaleExtend
          );
        } else if (localEnhanceOption === "2") {
          if (upscaleTypeData?.id === 1) {
            GenerateWorkFlowUpscale(
              (res) => handleApiResponse(res),
              payloadUpscale
            );
          } else {
            GenerateWorkFlowUpscaleCreative(
              (res) => handleApiResponse(res),
              payloadUpscale
            );
          }
        } else {
          GenerateWorkFlowUpscaleCharacter(
            (res) => handleApiResponse(res),
            payloadUpscale
          );
        }
      }
    };
    if (
      !((isActiveToolset === 2 && selection == "3") || isActiveToolset === 3)
    ) {
      if (
        !templateData?.isTemplate &&
        (!templateData?.isAtmosphere || referenceImage?.length === 0) &&
        (!templateData?.isPrompt || promptLocalData?.prompt === "") &&
        (!templateData?.isPrivate || promptLocalData?.privateModel?.value === 1)
      ) {
        setTemplateSelection(true);
      } else {
        handleFullFillAllRequirements();
      }
    } else {
      handleFullFillAllRequirements();
    }
  };

  const handleGenerateUpscale = () => {
    const payloadUpscale = buildUpscalePayload(upscaleToolsetData);
    GenerateWorkFlowUpscale((res) => handleApiResponse(res), payloadUpscale);
  };

  const generateButtonText = () => {
    if (isActiveToolset === 0) {
      if (maxCount < isPrivilagesDetails?.concurrent) {
        return t("generate");
      } else {
        return t("max");
      }
    }

    if (isActiveToolset === 1) {
      if (maxCount < isPrivilagesDetails?.concurrent) {
        return isDesignToolsetBaseImg ? t("add_base_image") : t("generate");
      } else {
        return t("max");
      }
    }
    if (isActiveToolset === 2) {
      if (maxCount > isPrivilagesDetails?.concurrent) return t("max");
      const selection = localStorage.getItem(`selection_${lastPart}`);
      if (selection == "2") {
        return isImageToolsetBaseImg || isImageToolsetMaskImg
          ? t("add_base_image")
          : isImageToolsetCNmg
          ? t("add_cn_image")
          : t("generate");
      } else {
        return isImageToolsetBaseImg || isImageToolsetMaskImg
          ? t("add_base_image")
          : t("generate");
      }
    }
    if (isActiveToolset === 3) {
      if (maxCount < isPrivilagesDetails?.concurrent) {
        return isUpscaleToolsetBaseImg ? t("add_base_image") : t("generate");
      } else {
        return t("max");
      }
    }
    return t("generate");
  };

  const isGenerateButtonDisabledFromMissingInput = () => {
    const selection = localStorage.getItem(`selection_${lastPart}`);
    if (isLoader) return true;
    if (isReferenceImg) return true;
    if (maxCount < isPrivilagesDetails?.concurrent) {
      if (isActiveToolset === 0) return false;
      if (isActiveToolset === 1) return isDesignToolsetBaseImg;
      if (isActiveToolset === 2 && selection == "2") {
        return (
          isImageToolsetBaseImg || isImageToolsetMaskImg || isImageToolsetCNmg
        );
      }
      if (isActiveToolset === 2)
        return isImageToolsetBaseImg || isImageToolsetMaskImg;
      if (isActiveToolset === 3) return isUpscaleToolsetBaseImg;
      return false;
    } else return false;
  };

  const preGenerationCheck = () => {
    const concurrent = isPrivilagesDetails?.concurrent ?? 0;
    maxCount >= concurrent || maxCount >= 4
      ? handleMaxButtonClick()
      : setTimeout(handleGenerate, 500);
  };

  useEffect(() => {
    isGenerateButtonDisabledFromMissingInput();
  }, [isGenerateButtonDisabledFromMissingInput()]);

  const closeSuccessGeneration = () => {
    SetImageGenerated(false);
  };

  const deleteTitle = `${t("delete")} 1 ${t("image_text")}`;
  const deleteContent = `${t("delete_the_selected")} 1 ${t("image?")}`;

  useEffect(() => {
    if (Array.isArray(isSetMessages)) {
      const count = isSetMessages.reduce((total, item) => {
        if (item.singleCard === true) {
          return total + 1;
        }
        return total;
      }, 0);
      setIsBeforeGenerateCount(count);
    }
    if (Array.isArray(generatedAllImages)) {
      const count = generatedAllImages.reduce((total, item) => {
        if (
          item.projectMedias &&
          item.projectMedias.length > 0 &&
          (item.projectMedias[0].toolset === "upscale" ||
            item.projectMedias[0].toolset === "upscale_feed")
        ) {
          return total + 1;
        }
        return total;
      }, 0);
      setIsAfterGenerateCount(count + count + count);
    }
    if (Array.isArray(generatedAllImages)) {
      const count = generatedAllImages.reduce((total, item) => {
        if (
          item.projectMedias &&
          item.projectMedias.length > 0 &&
          item.projectMedias[0].imageRatio === "16:9"
        ) {
          return total + 1;
        }
        return total;
      }, 0);
      setIsAfterGenerateCount1(count + count);
    }
  }, [isSetMessages, generatedAllImages]);

  const handleDragStart = (event, media) => {
    event.dataTransfer.setData("media", JSON.stringify(media));
    //this is to set the media to pass to the toolsets
  };

  const handleThumbnailDragStart = (event, media) => {
    //the code below is correct but most of the time downloadUrl is blocked, bad UX
    // const downloadUrl = `application/octet-stream:${media.fileName}:${media.thumbnail}`;
    // event.dataTransfer.setData("DownloadURL", downloadUrl);
  };

  const scrollToImage = () => {
    if (mostRecentImageRef.current) {
      mostRecentImageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    SetImageGenerated(false);
  };

  const handlePopup = () => {
    setIsShowFeedPopup(true);
  };

  const closePopup = () => {
    setIsShowFeedPopup(false);
  };

  const handleEditProject = () => {
    setEditProjectPopup(!editProjectPopup);
  };

  useEffect(() => {
    if (editProjectPopup === false) {
      setIsNewProjectName(projectName);
    } else if (editProjectPopup === true) {
      handleEditChange(projectName);
    }
  }, [editProjectPopup]);

  const handleEditChange = (e) => {
    let inputText = e;
    const { truncatedText, remainingChars } = processText(inputText, 50);
    setIsNewProjectName(truncatedText);
    setRemaining1(Math.max(0, remainingChars));
  };

  const handleEditProjectName = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const payload = {
      id: params.id,
      name: isNewProjectName,
      teamId: teamId,
    };
    if (payload) {
      CreateOrUpdateProject((res) => {
        setProjectName(res?.data?.name);
        setEditProjectPopup(!editProjectPopup);
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  useEffect(() => {
    // if (projectName){
    updateCurrentProjectData({
      projectName: projectName,
      event: handleEditProject,
      isShowLoadingCard: isShowLoadingCard,
    });
    // }
  }, [projectName]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (isSetMessages?.length !== 0) {
        if (window.confirm(t("exit_message"))) {
          setFinishStatus(true);
          navigate(-2);
        } else {
          window.history.pushState(null, window.location.pathname);
          setFinishStatus(false);
        }
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [finishStatus, navigate]);

  const handleImageLoad = (generationId, mediaId) => {
    setLoadedImages((prevLoadedImages) => {
      const newLoadedImages = {
        ...prevLoadedImages,
        [generationId]: {
          ...prevLoadedImages[generationId],
          [mediaId]: true,
        },
      };

      // Check if all images are loaded
      const allLoaded = Object.values(newLoadedImages).every((generation) =>
        Object.values(generation).every((status) => status === true)
      );
      // Update the allImagesLoaded state
      setAllImagesLoaded(allLoaded);

      return newLoadedImages;
    });
  };

  const areAllImagesLoadedInGenerationAndNotAllAreDeleted = (generationId) => {
    const mediaIds = Object.keys(loadedImages[generationId] || {});

    return (
      mediaIds.length > 0 &&
      mediaIds.every((mediaId) => loadedImages[generationId][mediaId]) &&
      !mediaIds.every((mediaId) => deletedMediaId.includes(Number(mediaId)))
    );
  };

  useEffect(() => {
    const favoritesMap = {};
    generatedAllImages.forEach((image) => {
      const projectMedias = image.projectMedias;
      projectMedias.forEach((media) => {
        favoritesMap[media.mediaId] =
          media.isFavorite || isFavoriteImages[media.mediaId];
      });
    });

    setIsFavoriteImages(favoritesMap);
  }, [generatedAllImages]);

  const handleFavorite = (id) => {
    const mediaId = id;
    const updatedIsFavoriteImages = { ...isFavoriteImages };
    const currentValue = updatedIsFavoriteImages[mediaId];
    updatedIsFavoriteImages[mediaId] = !currentValue;
    setIsFavoriteImages(updatedIsFavoriteImages);
    const payload = {
      mediaId: mediaId,
    };
    UpdateFavoriteImage((res) => {
      if (res?.status === 200) {
        const updatedFavoriteValue = res?.data?.isFavorite;
        // setIsFavoriteImages(updatedIsFavoriteImages);
      } else {
        const updatedIsFavoriteImages = { ...isFavoriteImages };
        const currentValue = updatedIsFavoriteImages[mediaId];
        updatedIsFavoriteImages[mediaId] = !currentValue;
        setIsFavoriteImages(updatedIsFavoriteImages);
      }
    }, payload);
  };

  useEffect(() => {
    if (isPrivilagesDetails) {
      const calculatedPercentage =
        (maxCount / isPrivilagesDetails?.concurrent) * 100;
      setPercentage(calculatedPercentage);
    }
  }, [maxCount, isPrivilagesDetails]);

  const isUpscale = generatedAllImages?.map((generation) =>
    generation?.projectMedias[0]?.toolset?.includes("upscale")
  );
  const trueCount = isUpscale.filter((value) => value === true).length;

  const handleTeamExit = () => {
    // apiCalledRef1.current = false;
    // localStorage.setItem("activeTeam", JSON.stringify(soloTeamData));
    navigate("/projects");
  };

  const handleDeletePopup = (mediaID = 0) => {
    setIsDeletePopup(!isDeletePopup);
    setMediaIDTBD(mediaID);
  };

  const handleDelete = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsLoader(true);
    const payload = {
      projectId: null,
      mediaIds: [mediaIDTBD],
    };
    if (payload) {
      DeleteImagesGallery((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          setDeletedMediaId((prev) => {
            const mediaId = payload.mediaIds[0];
            if (mediaId !== undefined && !prev.includes(mediaId)) {
              return [...prev, mediaId];
            }
            return prev;
          });
          setConfirmModal(true);
          setIsLoader(false);
          setAlertText(t("Images_deleted_successfully"));
        } else {
          setIsLoader(false);
          setConfirmModal(true);
          setAlertText(message);
        }
      }, payload);
    }
  };

  const handleDeleteClose = () => {
    setConfirmModal(false);
  };

  const handleUploadScaledClose = () => {
    setIsUploadScaled(false);
  };

  const handlePrev = () => {
    const mediaIindex = 0;
    const index = popUpData?.index - 1;
    const generation = generatedAllImages[popUpData?.index - 1];
    setPopUpData({ generation, mediaIindex, index });
  };

  const handleNext = () => {
    const mediaIindex = 0;
    const index = popUpData?.index + 1;
    const generation = generatedAllImages[popUpData?.index + 1];
    setPopUpData({ generation, mediaIindex, index });
  };

  useEffect(() => {
    if (isShowFeedPopup) {
      const handleKeyDown = (event) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isShowFeedPopup]);

  return (
    <UserPortalLayout>
      <div
        className="work-space"
        onClick={(event) => handleActiveLearnClose(activeLearnBool?.id, event)}
      >
        <DesignLibraryComopnent
          handleCloseAccessPopup={handleCloseAccessPopup}
          handleActiveLearn={handleActiveLearn}
          activeLearnBool={activeLearnBool}
          setIsButtonDisabled={setIsButtonDisabled}
          setIsLimitPopup={setIsLimitPopup}
          setGlobalErrorMessage={setGlobalErrorMessage}
          setIsUploadScaled={setIsUploadScaled}
          templateDisable={templateDisable}
          setIsActiveToolset={setIsActiveToolset}
        />
        {designLibraryData?.isShowTrueOrFalse && <AtmospherePopUp />}
        {designLibraryData?.isCloseTiresPopup && <TiresPopUp />}
        <div
          className="work-space__container"
          onClick={() => setHighlightedMediaId(null)}
        >
          <div
            className="work-space__main"
            ref={targetRef}
            data-scrollable="true"
          >
            <div
              className={`work-space__main__images ${
                generatedAllImages?.length +
                  isSetMessages?.length +
                  isBeforeGenerateCount +
                  isAfterGenerateCount +
                  isAfterGenerateCount1 +
                  trueCount <=
                (responsiveData ? 1 : 2)
                  ? "less"
                  : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {generatedAllImages?.length !== 0 ? (
                generatedAllImages?.length &&
                generatedAllImages?.map((generation, index) => {
                  if (generation) {
                    const { formattedTime, formattedDate } = convertDateTime1(
                      generation?.createdDate
                    );

                    const isMostRecentImage =
                      index === generatedAllImages.length - 1;
                    const loaded =
                      areAllImagesLoadedInGenerationAndNotAllAreDeleted(
                        generation.generationId
                      );

                    const isUpscale =
                      generation?.projectMedias[0]?.toolset?.includes(
                        "upscale"
                      );

                    const userData = generation?.projectMedias[0];

                    return (
                      <div
                        ref={isMostRecentImage ? mostRecentImageRef : null}
                        className={`work-space__main__images__list__data ${
                          isUpscale ? "one" : ""
                        }`}
                        key={index}
                      >
                        <div
                          className="work-space__main__images__list__data__content"
                          style={{
                            display:
                              loaded && allImagesLoaded ? "flex" : "none",
                          }}
                          onClick={() => setHighlightedMediaId(null)}
                        >
                          {isActiveTeam?.teamId !== 0.1 && (
                            <div className="work-space__main__images__list__data__icon">
                              <Popover
                                overlayClassName="custom-popover4"
                                placement="top"
                                content={
                                  <div className="inner-wrapper">
                                    {userData?.username}
                                  </div>
                                }
                              >
                                <img
                                  src={userData?.avatarUrl}
                                  alt="user-icon"
                                />
                              </Popover>
                            </div>
                          )}
                          <div className="work-space__main__images__list__data__time">
                            <p>{formattedTime}</p>
                            <p>{formattedDate}</p>
                          </div>
                          <div className="work-space__main__images__list__data__mode">
                            {generation?.projectMedias[0]?.stealth === true ? (
                              <img src={pro} alt="icon" />
                            ) : (
                              <img src={relax} alt="icon" />
                            )}
                            <div className="tool">
                              {/* {!responsiveData && ( */}
                              <ModeTooltip
                                name={
                                  generation?.projectMedias[0]?.stealth === true
                                    ? t("Private_mode")
                                    : t("Public_mode")
                                }
                                tooltipb_content={
                                  generation?.projectMedias[0]?.stealth === true
                                    ? t("tooltipb_content_Private")
                                    : t("tooltipb_content_Public")
                                }
                              />
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`work-space__main__images__list__images generated-images ${
                            isUpscale ? "one" : ""
                          }`}
                          style={{
                            display:
                              loaded && allImagesLoaded ? "flex" : "none",
                          }}
                          // onClick={() => setHighlightedMediaId(null)}
                        >
                          {generation?.projectMedias?.length &&
                            generation?.projectMedias?.map(
                              (media, mediaIndex) => {
                                const handleImageClick = () => {
                                  // if (media.mediaId === highlightedMediaId) {
                                  //   setHighlightedMediaId(null);
                                  // } else {
                                  handlePopup(generation);
                                  setPopUpData({
                                    generation,
                                    mediaIndex,
                                    index,
                                  });
                                  setHighlightedMediaId(media.mediaId);
                                  // }
                                };
                                const isDeleted =
                                  deletedMediaId.includes(media.mediaId) ||
                                  media.isActive === false;
                                const isFavorite =
                                  isFavoriteImages[media?.mediaId];
                                if (isDeleted) return null;
                                else
                                  return (
                                    <div
                                      key={mediaIndex}
                                      className={`work-space__main__images__list__images__image ${
                                        isUpscale ? "one" : ""
                                      } ${isFavorite ? "favorite" : ""}`}
                                      draggable={true}
                                      onDragStart={(event) =>
                                        handleDragStart(event, media)
                                      }
                                      style={{
                                        display: allImagesLoaded
                                          ? "flex"
                                          : "none",
                                      }}
                                    >
                                      <img
                                        className={`generated-img ${
                                          highlightedMediaId === media.mediaId
                                            ? "highlighted"
                                            : ""
                                        }`}
                                        data-draggable="true"
                                        onDragStart={(event) =>
                                          handleThumbnailDragStart(event, media)
                                        }
                                        src={media?.thumbnail}
                                        alt={media?.id}
                                        onLoad={() =>
                                          handleImageLoad(
                                            generation.generationId,
                                            media.mediaId
                                          )
                                        }
                                        onClick={() => {
                                          if (media?.url) {
                                            handleImageClick();
                                          }
                                        }}
                                      />
                                      <div
                                        className={`favorites ${
                                          isUpscale && "one"
                                        }`}
                                        onClick={
                                          media?.url
                                            ? handleImageClick
                                            : undefined
                                        }
                                      >
                                        {loginUserData?.id ===
                                        userData?.userId ? (
                                          <div
                                            className="fav-icon"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src={fav}
                                              className="fav"
                                              alt="favorite"
                                              onClick={() =>
                                                handleFavorite(media.mediaId)
                                              }
                                            />
                                            <img
                                              src={fav1}
                                              className="fav fav1"
                                              alt="favorite"
                                              onClick={() =>
                                                handleFavorite(media.mediaId)
                                              }
                                            />
                                            <img
                                              src={fav2}
                                              className="fav fav2"
                                              alt="favorite"
                                              onClick={() =>
                                                handleFavorite(media.mediaId)
                                              }
                                            />
                                            <div className="tooltip-show">
                                              {!responsiveData && (
                                                <FavoriteTooltip
                                                  text={t("favorite")}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="fav-icon"
                                            onClick={(e) => e.stopPropagation()}
                                            style={{ cursor: "not-allowed" }}
                                          >
                                            <img
                                              src={fav}
                                              className="fav"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <img
                                              src={fav1}
                                              className="fav fav1"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <img
                                              src={fav2}
                                              className="fav fav2"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <div className="tooltip-show">
                                              {!responsiveData && (
                                                <FavoriteTooltip
                                                  text={
                                                    t("favorite") +
                                                    t(
                                                      "action_disallowed_other_team"
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {loginUserData?.id ===
                                        userData?.userId ? (
                                          <div
                                            className="fav-icon"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src={del}
                                              className="del"
                                              alt="favorite"
                                              onClick={() =>
                                                handleDeletePopup(media.mediaId)
                                              }
                                            />
                                            <img
                                              src={del1}
                                              className="del del1"
                                              alt="favorite"
                                              onClick={() =>
                                                handleDeletePopup(media.mediaId)
                                              }
                                            />
                                            <img
                                              src={del2}
                                              className="del del2"
                                              alt="favorite"
                                              onClick={() =>
                                                handleDeletePopup(media.mediaId)
                                              }
                                            />
                                            <div className="tooltip-show">
                                              {!responsiveData && (
                                                <FavoriteTooltip
                                                  text={t("delete")}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="fav-icon"
                                            style={{ cursor: "not-allowed" }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src={del}
                                              className="del"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <img
                                              src={del1}
                                              className="del del1"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <img
                                              src={del2}
                                              className="del del2"
                                              alt="favorite"
                                              style={{ cursor: "not-allowed" }}
                                            />
                                            <div className="tooltip-show">
                                              {!responsiveData && (
                                                <FavoriteTooltip
                                                  text={
                                                    t("delete") +
                                                    t(
                                                      "action_disallowed_other_team"
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                              }
                            )}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <div className="gif-icon">
                  {isLoader && <img className="gif" src={gif} alt="gif" />}
                </div>
              )}
              {isSetMessages?.length !== 0 &&
                isSetMessages?.map((val, index) => {
                  let count = val.imageCount;
                  return (
                    <div
                      className="work-space__main__images__list__data default"
                      key={index}
                    >
                      <div className="work-space__main__images__list__data__content">
                        {isActiveTeam?.teamId !== 0.1 && (
                          <Popover
                            overlayClassName="custom-popover4"
                            placement="top"
                            content={
                              <div className="inner-wrapper">
                                {loginUserData?.name}
                              </div>
                            }
                          >
                            <div className="work-space__main__images__list__data__icon">
                              <img
                                src={loginUserData?.avatarUrl}
                                alt="user-icon"
                              />
                            </div>
                          </Popover>
                        )}
                        <div className="work-space__main__images__list__data__time">
                          <p>-- --</p>
                          <p>-- -- ----</p>
                        </div>
                        <div className="work-space__main__images__list__data__mode">
                          {val?.boolean === true && (
                            <>
                              {isPrivilagesDetails?.stealthImage === true ? (
                                <img src={pro} alt="icon" />
                              ) : (
                                <img src={relax} alt="icon" />
                              )}
                              <div className="tool">
                                {/* {!responsiveData && ( */}
                                <ModeTooltip
                                  name={
                                    isPrivilagesDetails?.stealthImage === true
                                      ? t("Private_mode")
                                      : t("Public_mode")
                                  }
                                  tooltipb_content={
                                    isPrivilagesDetails?.stealthImage === true
                                      ? t("tooltipb_content_Private")
                                      : t("tooltipb_content_Public")
                                  }
                                />
                                {/* )} */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {val?.boolean === true && (
                        <div className="work-space__main__images__list__images">
                          {val?.singleCard === true ? (
                            <div className="work-space__main__images__list__images__image one">
                              {!val?.isBrockenImages ? (
                                <React.Fragment>
                                  <img src={defaultimgbig} alt="" />
                                  <img
                                    src={
                                      val?.status === "started to upload"
                                        ? dots1
                                        : dots
                                    }
                                    className="dots1"
                                    alt="dots-gif"
                                  />
                                  {val?.status === "queuing" ? (
                                    <>
                                      <p>{t("queuing")}</p>
                                      <h6>
                                        {t("queuing_content1")}
                                        {val?.myQueue} {t("queuing_content3")}
                                      </h6>
                                    </>
                                  ) : (
                                    <>
                                      <p>
                                        {val?.isTimer < 0 &&
                                        val?.status !== "started to upload"
                                          ? t("traffic")
                                          : t(
                                              val?.status ===
                                                "started to upload"
                                                ? val?.isTimer < 0
                                                  ? "traffic_01"
                                                  : "text_02"
                                                : "text_01"
                                            )}
                                      </p>
                                      {val?.status === "generating" && (
                                        <h6>
                                          {t("estimated_time")} {val?.isTimer} s
                                        </h6>
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ) : (
                                <img
                                  src={
                                    isActiveLanguage === "en"
                                      ? brokenen
                                      : brokench
                                  }
                                  alt="broken-img"
                                />
                              )}
                            </div>
                          ) : (
                            <div className="work-space__main__images__list__images__new">
                              {[...Array(Number(count))].map(
                                (_, imageIndex) => {
                                  return (
                                    <div
                                      className="work-space__main__images__list__images__image"
                                      key={imageIndex}
                                    >
                                      {!val?.isBrockenImages ? (
                                        <React.Fragment>
                                          <img src={defaultimg} alt="" />
                                          <img
                                            src={
                                              val?.status ===
                                              "started to upload"
                                                ? dots1
                                                : dots
                                            }
                                            className="dots"
                                            alt="dots-gif"
                                          />
                                          {val?.status === "queuing" ? (
                                            <>
                                              <p>{t("queuing")}</p>
                                              <h6>
                                                {t("queuing_content1")}
                                                {val?.myQueue}{" "}
                                                {t("queuing_content3")}
                                              </h6>
                                            </>
                                          ) : (
                                            <>
                                              <p>
                                                {val?.isTimer < 0 &&
                                                val?.status !==
                                                  "started to upload"
                                                  ? t("traffic")
                                                  : t(
                                                      val?.status ===
                                                        "started to upload"
                                                        ? val?.isTimer < 0
                                                          ? "traffic_01"
                                                          : "text_02"
                                                        : "text_01"
                                                    )}
                                              </p>
                                              {val?.status === "generating" && (
                                                <h6>
                                                  {t("estimated_time")}{" "}
                                                  {val?.isTimer} s
                                                </h6>
                                              )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <img
                                          src={
                                            isActiveLanguage === "en"
                                              ? brokenen
                                              : brokench
                                          }
                                          alt="broken-img"
                                        />
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              {/* {showBrockenImages && (
                <div className="work-space__main__images__list__data">
                  <div className="work-space__main__images__list__data__time">
                    <p></p>
                  </div>
                  {showSingleCard === true ? (
                    <div className="work-space__main__images__list__images">
                      <div className="work-space__main__images__list__images__image one">
                        <img
                          src={isActiveLanguage === "en" ? brokenen : brokench}
                          alt="broken-img"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="work-space__main__images__list__images">
                      <div className="work-space__main__images__list__images__image">
                        <img
                          src={isActiveLanguage === "en" ? brokenen : brokench}
                          alt="broken-img"
                        />
                      </div>
                      <div className="work-space__main__images__list__images__image">
                        <img
                          src={isActiveLanguage === "en" ? brokenen : brokench}
                          alt="broken-img"
                        />
                      </div>
                      <div className="work-space__main__images__list__images__image">
                        <img
                          src={isActiveLanguage === "en" ? brokenen : brokench}
                          alt="broken-img"
                        />
                      </div>
                      <div className="work-space__main__images__list__images__image">
                        <img
                          src={isActiveLanguage === "en" ? brokenen : brokench}
                          alt="broken-img"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )} */}
              {isShowFeedPopup && (
                <WorkSpaceFeedPopUp
                  isButtonDisabled={isButtonDisabled}
                  setIsButtonDisabled={setIsButtonDisabled}
                  generation={popUpData?.generation}
                  mediaIndex={popUpData?.mediaIndex}
                  closePopup={closePopup}
                  setIsLimitPopup={setIsLimitPopup}
                  handleClosePopupGlobal={handleClosePopupGlobal}
                  setGlobalErrorMessage={setGlobalErrorMessage}
                  setDeletedMediaId={setDeletedMediaId}
                  showStealth={true}
                  isMaxCount={maxCount}
                  isPrivilageCount={isPrivilagesDetails?.concurrent}
                  isMaxButtonClick={handleMaxButtonClick}
                  isFavoriteImages={isFavoriteImages}
                  setIsFavoriteImages={setIsFavoriteImages}
                  handleGenerateUpscale={handleGenerateUpscale}
                  setIsUploadScaled={setIsUploadScaled}
                  setTemplateDisable={setTemplateDisable}
                  handleClickPrev={handlePrev}
                  handleClickNext={handleNext}
                  generationIndex={popUpData?.index}
                  generatedAllImages={generatedAllImages}
                  setHighlightedMediaId={setHighlightedMediaId}
                />
              )}
            </div>
          </div>

          <div className={`back-to-bottom ${showButton ? "show" : ""}`}>
            <button onClick={handleClick}>
              <img src={downarrow} alt="down-arrow" />
            </button>
          </div>
          {isActiveTeam?.teamId !== 0.1 && (
            <div className={"reload-feed"}>
              <button onClick={reloadFeed}>
                <Popover
                  overlayClassName="custom-popover4"
                  placement="top"
                  content={
                    <div className="inner-wrapper">{t("click_to_reload")}</div>
                  }
                >
                  <img src={refreshButton} alt="down-arrow" />
                </Popover>
              </button>
            </div>
          )}
          <div className="alert">
            {isGpuLoadAlert && (
              <div className="alert__message">
                <img src={alerticon} alt="alert-icon" />
                <p>{t("heavy_traffiv")}</p>
              </div>
            )}
            {isNetworkLoadAlert && (
              <div className="alert__message">
                <img src={alerticon} alt="alert-icon" />
                <p>{t("network_unstable")}</p>
              </div>
            )}
            {showMaxAlert1 && (
              <div className="alert__message">
                <img src={alerticon} alt="alert-icon" />
                <p>
                  {t("max_text1")} {maxCount} {t("max_text2")}
                </p>
              </div>
            )}
            {templateSelection && (
              <div className="alert__message message2">
                <div className="alert__message__main1">
                  <img src={alerticon} alt="alert-icon" />
                  <span>{t("please_add_ref_popup")}</span>
                </div>
                <div className="alert__message__buttons1">
                  <button onClick={() => setTemplateSelection(false)}>
                    {t("ok")}
                  </button>
                </div>
              </div>
            )}
            {showMaxAlert && (
              <div className="alert__message message1">
                <div className="alert__message__heading">
                  <img src={alerticon} alt="alert-icon" />
                  <h4>{t("reached_max_concurrent")}</h4>
                </div>
                <div className="alert__message__content">
                  <p>{t("reached_max_concurrent_content")}</p>
                </div>
                <div className="alert__message__buttons">
                  <div className="alert__message__buttons__section">
                    <button
                      onClick={() => setShowMaxAlert(false)}
                      className="cancel"
                    >
                      {t("ok")}
                    </button>
                    <button
                      onClick={() => {
                        handleShowSubscription();
                        setShowMaxAlert(false);
                      }}
                    >
                      {t("view_plans")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {imageGenerated && (
              <div className="alert__message">
                <div className="alert__message__main">
                  <div className="alert__message__main__content">
                    <div className="alert__message__main__content__data">
                      {projectName !== latestImageGeneratedName ? (
                        <img src={alerticon} alt="alert-icon" />
                      ) : (
                        <></>
                      )}
                      <p>
                        {projectName !== latestImageGeneratedName ? (
                          <span>
                            {t("success_generation1")}
                            <strong style={{ fontWeight: "bold" }}>
                              {latestImageGeneratedName}
                            </strong>
                            {t("success_generation2")}
                          </span>
                        ) : (
                          t("success_generation")
                        )}
                      </p>
                    </div>
                    {projectName !== latestImageGeneratedName ? (
                      <img
                        className="cancel"
                        src={cancelButton}
                        alt="alert-icon"
                        onClick={closeSuccessGeneration}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={`${
                      projectName === latestImageGeneratedName
                        ? "alert__message__buttons"
                        : ""
                    }`}
                  >
                    {projectName === latestImageGeneratedName ? (
                      <>
                        <button
                          className="cancel"
                          onClick={closeSuccessGeneration}
                        >
                          {t("cancel")}
                        </button>
                        <button className="success" onClick={scrollToImage}>
                          {t("clickheretoview")}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="work-space__button" key={isNewProject}>
            {/* {isLogin === false ? (
              <button disabled={true}>
                <span>
                  {isExample === true
                    ? t("example_project_name")
                    : t("team_project_name")}
                </span>
              </button>
            ) : (
              <> */}
            {isNewProject ? (
              <button
                id="generate-button"
                disabled={true}
                onClick={preGenerationCheck}
              >
                <div
                  style={{
                    "--fill-width": `${percentage}%`,
                    "--rotate-deg": `${percentage * 3.6 + 45}deg`,
                    backgroundColor:
                      maxCount === isPrivilagesDetails?.concurrent
                        ? "#ffffff"
                        : "#d9d9d9",
                  }}
                  className="button-fill"
                ></div>
                <div className="button-fill-bg"></div>
                <span
                  style={{
                    color:
                      maxCount === isPrivilagesDetails?.concurrent ||
                      isGenerateButtonDisabledFromMissingInput()
                        ? "black"
                        : "#ffffff",
                  }}
                >
                  {generateButtonText()}{" "}
                  {`${maxCount}/${isPrivilagesDetails?.concurrent || 0}`}
                </span>
              </button>
            ) : (
              <button
                id="generate-button"
                disabled={isGenerateButtonDisabledFromMissingInput()}
                onClick={preGenerationCheck}
              >
                <div
                  style={{
                    "--fill-width": `${percentage}%`,
                    "--rotate-deg": `${percentage * 3.6 + 45}deg`,
                    backgroundColor:
                      maxCount === isPrivilagesDetails?.concurrent
                        ? "#ffffff"
                        : "#d9d9d9",
                  }}
                  className="button-fill"
                ></div>
                <div className="button-fill-bg"></div>
                <span
                  style={{
                    color:
                      maxCount === isPrivilagesDetails?.concurrent ||
                      isGenerateButtonDisabledFromMissingInput()
                        ? "black"
                        : "#ffffff",
                  }}
                >
                  {generateButtonText()}{" "}
                  {`${maxCount}/${isPrivilagesDetails?.concurrent || 0}`}
                </span>
              </button>
            )}
            {/* </>
            )} */}
          </div>
        </div>
        <ToolSetComponent
          setIsButtonDisabled={setIsButtonDisabled}
          setIsLimitPopup={setIsLimitPopup}
          handleClosePopupGlobal={handleClosePopupGlobal}
          setGlobalErrorMessage={setGlobalErrorMessage}
          handleActiveLearn={handleActiveLearn}
          activeLearnBool={activeLearnBool}
          setActiveLearnBool={setActiveLearnBool}
          handleShowSubscription={handleShowSubscription}
          setIsUploadScaled={setIsUploadScaled}
          setTemplateDisable={setTemplateDisable}
          setIsActiveToolset={setIsActiveToolset}
          isActiveToolset={isActiveToolset}
        />
        {renderToolsetData?.showPopUpForBaseImage && (
          <ImageMaskPopUp
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
            setIsLimitPopup={setIsLimitPopup}
            handleClosePopupGlobal={handleClosePopupGlobal}
            setGlobalErrorMessage={setGlobalErrorMessage}
          />
        )}
        {editProjectPopup && (
          <div className="edit-project" onClick={(e) => e.stopPropagation()}>
            <div
              className="edit-project__container"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="edit-project__section">
                <img
                  className="cross"
                  src={cross}
                  alt="cross-icon"
                  onClick={handleEditProject}
                />
                <div className="edit-project__section__heading">
                  <h3>{t("edit_project_name")}</h3>
                </div>
                <div className="edit-project__section__input">
                  <input
                    placeholder="Project Name"
                    value={isNewProjectName}
                    onChange={(e) => handleEditChange(e.target.value)}
                    // onChange={(e) => setIsNewProjectName(e.target.value)}
                  />
                  <p style={{ color: remaining1 === 50 && "red" }}>
                    <span>{remaining1}</span> / 50
                  </p>
                </div>
                <div className="edit-project__section__button">
                  <button onClick={handleEditProjectName}>{t("submit")}</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isDeletePopup && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={deleteTitle}
            content={deleteContent}
            onConfirm={handleDelete}
            onCancel={handleDeletePopup}
            cancelButtonText={t("cancel")}
            buttonText={t("delete")}
          />
        )}
        {confirmModal && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={alertText}
            onConfirm={handleDeleteClose}
            onCancel={handleDeleteClose}
          />
        )}
        {isLimitPoup === true && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={t("limit_heading")}
            content={t("limit_content")}
            onConfirm={handleViewPlans}
            onCancel={handleCloseLimit}
            cancelButtonText={t("ok")}
            buttonText={t("view_plans")}
          />
        )}
        {isExitTeam && (
          <Confirm
            isCancelRequired={false}
            confirmTitle={t("team_switch_heading")}
            content={t("team_switch_content")}
            onConfirm={handleTeamExit}
            buttonText={t("ok")}
          />
        )}
        {isUploadScaled === true && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={t("image_scaled_title")}
            content={t("image_scaled_content")}
            onConfirm={handleUploadScaledClose}
            buttonText={t("ok")}
          />
        )}
        {isGptActive === true && (
          <>
            {isGptActivePopup === true && (
              <PLanEnd
                handleCloseAccessPopup={handleCloseAccessPopup}
                handleSubscription={handleSubscription}
              />
            )}
          </>
        )}
        {globalError && (
          <GlobalErrorPopup
            heading={globalErrorMessage}
            handleClosePopupGlobal={handleClosePopupGlobal}
          />
        )}
      </div>
    </UserPortalLayout>
  );
};

export default Workspace;
