/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import "./News.scss";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import {
  GetAllNewsWithPagination,
  GetAllTeamNotifications,
  GetUserTeamNotification,
} from "../../utils/apiCalls";
import { t } from "i18next";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const News = () => {
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const { updateSocketData } = useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const { teamMemberNotification, handleTeamMemberNofications } =
    useContext(UserSocketContext);
  const newsApiRef = useRef(false);

  const [activePage, setActivePage] = useState(1);
  const [activeNewPage, setActiveNewPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [newPageSize, setNewPageSize] = useState(10);
  const [newsDataList, setNewsDataList] = useState([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState("");
  const [totalPagesCount, setTotalPagesCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTabId, setIsTabId] = useState(1);

  let lang = localStorage?.getItem("language");

  const tabsList = [
    {
      id: 1,
      name: t("news_heading1"),
    },
    {
      id: 2,
      name: t("news_heading2"),
    },
    {
      id: 3,
      name: t("news_heading3"),
    },
    {
      id: 4,
      name: t("news_heading4"),
    },
    {
      id: 5,
      name: t("news_heading5"),
    },
  ];

  useEffect(() => {
    if (teamMemberNotification) {
      setIsTabId(3);
      GetAllNewsData(3);
    } else {
      setIsTabId(1);
      GetAllNewsData(1);
    }
  }, [isTeamChanges]);

  useEffect(() => {
    if (teamMemberNotification) {
      setIsTabId(3);
      GetAllNewsData(3);
    }
  }, [teamMemberNotification]);

  const handlePageChange = (event) => {
    setActiveNewPage(event);
  };

  const GetAllNewsData = (id) => {
    setIsLoading(true);
    const language = lang === "ch" ? true : false;
    const payload = {
      pageSize: newPageSize ? newPageSize : pageSize,
      pageIndex: activeNewPage - 1 ? activeNewPage - 1 : activePage - 1,
    };
    if (!newsApiRef.current) {
      newsApiRef.current = true;
      if (payload) {
        if (id === 3) {
          GetAllTeamNotifications(
            (res) => {
              if (res.status === 200) {
                handleTeamMemberNofications();
                setIsLoading(false);
                setNewsDataList(res.data);
                setTotalRecordsCount(res.totalRecords);
                setTotalPagesCount(res.totalPages);
              } else {
                setIsLoading(false);
              }
              if (res?.message === "Invalid access token") {
                updateSocketData({
                  isExitPopup: true,
                });
              }
            },
            language,
            payload
          );
        } else {
          GetAllNewsWithPagination(
            (res) => {
              if (res.status === 200) {
                setIsLoading(false);
                setNewsDataList(res.data);
                setTotalRecordsCount(res.totalRecords);
                setTotalPagesCount(res.totalPages);
              } else {
                setIsLoading(false);
              }
              if (res?.message === "Invalid access token") {
                updateSocketData({
                  isExitPopup: true,
                });
              }
            },
            payload,
            language,
            id
          );
        }
      }
    }
  };

  const handleSelect = (id) => {
    newsApiRef.current = false;
    setNewsDataList([]);
    setIsTabId(id);
    GetAllNewsData(id);
  };

  return (
    <UserPortalLayout>
      <div className="news">
        <SideNavComponent />
        <div className="news__container">
          <div className="news__sections">
            <div className="news__main" data-scrollable="true">
              <div className="news__main__tabs">
                <div className="news__main__tabs__tab-list">
                  {isActiveTeam?.teamId === 0.1
                    ? tabsList
                        // .filter((tab) => tab.id !== 3)
                        .map((tab) => {
                          return (
                            <h4
                              key={tab.id}
                              className={`news__main__tabs__tab ${
                                isTabId === tab.id ? "active" : ""
                              }`}
                              onClick={() => handleSelect(tab.id)}
                            >
                              {tab.name}
                            </h4>
                          );
                        })
                    : tabsList.map((tab) => {
                        return (
                          <h4
                            key={tab.id}
                            className={`news__main__tabs__tab ${
                              isTabId === tab.id ? "active" : ""
                            }`}
                            onClick={() => handleSelect(tab.id)}
                          >
                            {tab.name}
                          </h4>
                        );
                      })}
                </div>
                {isTabId !== 3 ? (
                  <div className="news__main__section">
                    {newsDataList?.length !== 0 ? (
                      newsDataList?.map((news, index) => {
                        const formattedMessage = news?.description
                          ? news?.description?.trim()?.replace(/\\n/g, "<br>")
                          : "";
                        return (
                          <div className="news__main__card" key={index}>
                            <div className="news__main__card__container">
                              <div className="news__main__card__heading">
                                <div className="news__main__card__heading__left">
                                  <h3>{news.title}</h3>
                                  <span>{news.subTitle}</span>
                                </div>
                                <div className="news__main__card__heading__right">
                                  <h6>
                                    {new Date(news.createdDate)
                                      .toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })
                                      .replace(/\//g, ".")}
                                  </h6>
                                </div>
                              </div>
                              <div className="news__main__card__content">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: formattedMessage,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="gif">
                        {isLoading ? (
                          <img src={loader} alt="gif-loader" />
                        ) : (
                          <p style={{ width: "100%", textAlign: "center" }}>
                            {t("no_data_found")}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="news__main__section">
                    {newsDataList?.length !== 0 ? (
                      newsDataList?.map((news, index) => {
                        const fullStop = news?.description?.trim().endsWith('.');
                        return (
                          <div className="news__main__card" key={index}>
                            <div className="news__main__card__container">
                              <div className="news__main__card__heading">
                                <div className="news__main__card__heading__left">
                                  <div className="news__main__card__heading__left__image">
                                    <img
                                      src={news?.teamAvatarUrl}
                                      alt={news?.teamName}
                                    />
                                    <h3>
                                      {t("team")} : {news.teamName}
                                    </h3>
                                  </div>
                                  <span>{news?.description}{lang !== "ch" && !fullStop && "."}</span>
                                </div>
                                <div className="news__main__card__heading__right">
                                  <h6>
                                    {new Date(news?.createdOn)
                                      .toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })
                                      .replace(/\//g, ".")}
                                  </h6>
                                </div>
                              </div>
                              <div className="news__main__card__content">
                                <p>{news?.message}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="gif">
                        {isLoading ? (
                          <img src={loader} alt="gif-loader" />
                        ) : (
                          <p style={{ width: "100%", textAlign: "center" }}>
                            {t("no_data_found")}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default News;
