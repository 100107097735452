/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import "./AtmospherePopUp.scss";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import spring from "../../../assets/images/workspace/atmosphere/spring.png";
import autumn from "../../../assets/images/workspace/atmosphere/autumn.png";
import winter from "../../../assets/images/workspace/atmosphere/winter.png";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import foggy from "../../../assets/images/workspace/atmosphere/foggy.png";
import rainy from "../../../assets/images/workspace/atmosphere/rainy.png";
import sunset from "../../../assets/images/workspace/atmosphere/sunset.png";
import evening from "../../../assets/images/workspace/atmosphere/evening.png";
import night from "../../../assets/images/workspace/atmosphere/night.png";
import defaulticon from "../../../assets/images/icons/default.png";
import { GetCameraViewUrlUser } from "../../../utils/apiCalls";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";

const AtmospherePopUp = () => {
  const { t } = useTranslation();
  const mainMenu = [
    {
      id: 1,
      image: spring,
      name: t("spring"),
    },
    {
      id: 2,
      image: autumn,
      name: t("autumn"),
    },
    {
      id: 3,
      image: winter,
      name: t("winter"),
    },
  ];
  const sideMenu = [
    {
      id: 1,
      image: sunny,
      name: t("sunny"),
    },
    {
      id: 2,
      image: foggy,
      name: t("foggy"),
    },
    {
      id: 3,
      image: rainy,
      name: t("rainy"),
    },
    {
      id: 4,
      image: sunset,
      name: t("sunset"),
    },
    {
      id: 5,
      image: evening,
      name: t("evening"),
    },
    {
      id: 6,
      image: night,
      name: t("night"),
    },
  ];

  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);

  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const cameraData = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
  const dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  const { designLibraryData, updateDesignLibraryData } =
    useContext(DesignLibraryContext);

  const [atmosphereData, setAtmosphereData] = useState([]);
  const [selectedAtmosphereId, setSelectedAtmosphereId] = useState(null);
  const [selectedAtmosphereUrl, setSelectedAtmosphereUrl] = useState();

  useEffect(() => {
    getAtmosphereData();
  }, [cameraData?.cameraViewId]);

  const handleAtmosphereClick = (atom, sideMenuItem, mainMenuItem) => {
    setSelectedAtmosphereId(atom?.id);
    setSelectedAtmosphereUrl(atom?.thumbnail);
    let atmosphere = JSON.parse(
      localStorage.getItem(`atmosphereData_${lastPart}`)
    );

    if (atmosphere?.defaultSelectedAtmosphereUrl) {
      atmosphere.defaultSelectedAtmosphereId = atom?.id || selectedAtmosphereId;
      atmosphere.defaultSelectedAtmosphereUrl =
        atom?.thumbnail || selectedAtmosphereUrl;
      atmosphere.seasonName = sideMenuItem?.name || "sunny";
      atmosphere.seasonIcon = sideMenuItem?.image || sunny;
      atmosphere.seasonNameSelected = mainMenuItem?.name || "spring";
      atmosphere.seasonNameSelectedId = mainMenuItem?.id || 1;
    }
    localStorage.setItem(
      `atmosphereData_${lastPart}`,
      JSON.stringify(atmosphere)
    );

    const newData = {
      isShowTrueOrFalse: false,
    };
    updateDesignLibraryData(newData);
  };

  const getAtmosphereData = () => {
      const designLibraryType = dlData?.prevDesignLibraryId ?? 22;
    if (designLibraryType) {
      if (cameraData?.cameraViewId) {
        GetCameraViewUrlUser((res) => {
          if (res?.status === 200) {
            setAtmosphereData(res?.data?.slice(0, 18));
          } else {
            setAtmosphereData([]);
          }
        }, designLibraryType);
      }
    }
  };

  const handleCloseClick = () => {
    const newData = {
      isShowTrueOrFalse: false,
    };
    updateDesignLibraryData(newData);
  };

  const totalChunks = 6;
  const atmosphereDataChunks = Array.from({ length: totalChunks }, () => []);

  atmosphereData.forEach((atom, index) => {
    const chunkIndex = index % totalChunks;
    atmosphereDataChunks[chunkIndex].push({
      atom,
      sideMenuItem: sideMenu[chunkIndex],
    });
  });

  const isActive = atmosphere?.defaultSelectedAtmosphereId === 99;

  const atom1 = {
    id: 99,
    thumbnail:
      "https://airi-development.s3.cn-north-1.amazonaws.com.cn/new_atmosphere_20230727/01_01_spring_sunny_thumbnail_140.png",
  };
  const sideMenuItem1 = {};
  const mainMenuItem1 = {};

  return (
    <div className="background" onClick={handleCloseClick}>
      <div className="atmosphere" onClick={(e) => e.stopPropagation()}>
        <div className="atmosphere__container">
          <div className="atmosphere__main">
            <div className="atmosphere__main__heading">
              <h4>{t("atmosphere")}</h4>
              <img onClick={handleCloseClick} src={cross} alt="cross-icon" />
            </div>
            <div className="atmosphere__main__section">
              <div className="atmosphere__main__section__main-menus">
                {mainMenu?.length &&
                  mainMenu?.map((menu) => {
                    return (
                      <div
                        className={`atmosphere__main__section__main-menus__sec ${
                          atmosphere?.seasonNameSelectedId === menu?.id
                            ? "active"
                            : ""
                        }`}
                        key={menu?.id}
                      >
                        <img src={menu?.image} alt={menu?.name} />
                        <h5>{menu?.name}</h5>
                      </div>
                    );
                  })}
              </div>
              <div className="atmosphere__main__section__images">
                {/* <div className="atmosphere__main__section__images__side-menu">
                  <div className={`atmosphere__main__section__images__side-menu__header default ${isActive ? "active" : ""}`}>
                    <img src={defaulticon} alt="defaulticon" />
                    <h5>{t("default")}</h5>
                  </div>
                  <div className="atmosphere__main__section__images__side-menu__content">
                    <div
                      className={`atmosphere__main__section__image default ${
                        isActive ? "active" : ""
                      }`}
                      onClick={() =>
                        handleAtmosphereClick(
                          atom1,
                          sideMenuItem1,
                          mainMenuItem1
                        )
                      }
                    >
                      <img src={defaulticon} alt="defaulticon" />
                      <h5>{t("default")}</h5>
                    </div>
                  </div>
                </div> */}
                {atmosphereDataChunks?.length ? (
                  atmosphereDataChunks?.length &&
                  atmosphereDataChunks?.map((atmosphereChunk, chunkIndex) => {
                    const sideMenuItem = sideMenu[chunkIndex];
                    return (
                      <div
                        key={chunkIndex}
                        className="atmosphere__main__section__images__side-menu"
                      >
                        <div
                          className={`atmosphere__main__section__images__side-menu__header ${
                            atmosphere?.seasonName === sideMenuItem?.name
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            src={sideMenuItem?.image}
                            alt={sideMenuItem?.name}
                          />
                          <h5>{sideMenuItem?.name}</h5>
                        </div>
                        <div className="atmosphere__main__section__images__side-menu__content">
                          {atmosphereChunk?.length ? (
                            atmosphereChunk?.length &&
                            atmosphereChunk?.map(
                              ({ atom, sideMenuItem }, index) => {
                                const isSelected =
                                  atmosphere?.defaultSelectedAtmosphereId ===
                                  atom?.id;
                                return (
                                  <div
                                    className="atmosphere__main__section__image"
                                    key={atom?.id}
                                  >
                                    <img
                                      className={`${
                                        isSelected ? "active" : ""
                                      }`}
                                      src={atom?.thumbnail}
                                      onClick={() =>
                                        handleAtmosphereClick(
                                          atom,
                                          sideMenuItem,
                                          mainMenu[index]
                                        )
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtmospherePopUp;
