/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./DesignLibrary.scss";
import archib from "../../../assets/images/workspace/icons/archib.png";
import interior from "../../../assets/images/workspace/icons/interiorb.png";
import landsc from "../../../assets/images/workspace/icons/landscb.png";
// import graphic from "../../../assets/images/workspace/icons/graphicb.png";
import urbanDesign from "../../../assets/images/workspace/icons/urban-design.png";
import defaultimg from "../../../assets/images/workspace/default.png";
import defaulticon from "../../../assets/images/workspace/default-icon.png";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import intro from "../../../assets/images/workspace/icons/intro.png";
import template from "../../../assets/images/hover-tips-images/template.png";
import textComponent from "../../../assets/images/hover-tips-images/text-complement.png";
import stylerefimage from "../../../assets/images/hover-tips-images/style_reference.jpg";
import privateCustomModel from "../../../assets/images/hover-tips-images/private-custom-model.jpg";
import promptImage from "../../../assets/images/hover-tips-images/prompt.jpg";
import NegPromptImage from "../../../assets/images/hover-tips-images/negative-prompt.jpg";
import baseicon from "../../../assets/images/workspace/icons/base-icon.png";
import cameraimage from "../../../assets/images/hover-tips-images/camera.png";
import atmosphereimage from "../../../assets/images/hover-tips-images/atmosphere.png";
import arrow from "../../../assets/images/workspace/icons/arrow.png";
import refDelete from "../../../assets/images/workspace/icons/ref-delete.png";
import Loader from "../../../components/loader/index";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/UserDetailsStore";
import TipsPopups1 from "../../TipsPopup/TipsPopup1";
import {
  GetCameraViewUrlUser,
  GetCameraViewUser,
  GetFirstTierUser,
  GetSecondTierUser,
  GetThirdTierAndStyleUser,
  GetAllPrivateModel,
  CallAwsUrl,
  GetGraphicStyleData,
} from "../../../utils/apiCalls";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";
import { ToolsetDataContext } from "../../../store/WorkSpaceToolsetData";
import Portal from "../../TipsPopup/Portal1";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import Switch from "react-switch";
import Select from "react-select";
import { TeamDesignContext } from "../../../store/TeamDesignData";
import { AtmosphereRefContext } from "../../../store/AtmosphereRefData";
import { Slider } from "antd";
import {
  computePostProcessedDimension,
  handleBase64Data,
  processImage,
  uploadFile,
  SCALE_LIMIT,
} from "../../../utils/workspaceUtils";
import CameraDropDown from "../cameradropdown/CameraDropDown";
import LearnPopups from "../../TipsPopup/TipsPopups";
import TooltipRight from "../../Tooltips/TooltipRight";
import GraphicStyleDropDown from "../GraphicStyle/GraphicStyle";
import { processText } from "../../../utils/commonUtils";

const DesignLibraryComopnent = ({
  handleCloseAccessPopup,
  handleActiveLearn,
  activeLearnBool,
  setIsButtonDisabled,
  setIsLimitPopup,
  setGlobalErrorMessage,
  setIsUploadScaled,
  templateDisable,
  setIsActiveToolset
}) => {
  const { t } = useTranslation();
  const privateModelApiRef = useRef(false);
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const textareaRef = useRef(null);
  const textarea1Ref = useRef(null);

  const { isPrivilagesDetails } = useContext(UserContext);
  const { designLibraryData, updateDesignLibraryData } =
    useContext(DesignLibraryContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const { atmosphereRefData, updateAtmosphereRefData } =
    useContext(AtmosphereRefContext);

  const activeToolsetLocal = JSON.parse(
    localStorage.getItem(`activeToolset_${lastPart}`)
  );
  const lang = localStorage.getItem("language");
  const tiresData = JSON.parse(
    localStorage.getItem(`designLibraryData_${lastPart}`)
  );
  const atmosphereData = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const cameraData = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
  let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  let designLibrary = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  const promptLocalData = JSON.parse(
    localStorage.getItem(`promptData_${lastPart}`)
  );
  const atmosphereRefLocal = JSON.parse(
    localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
  );
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const slection = localStorage.getItem(`selection_${lastPart}`);
  const isLoginUser = localStorage.getItem("loginUser");
  const istemplateLocal = JSON.parse(
    localStorage.getItem(`isTemplate_${lastPart}`)
  );
  const localEnhanceOption = localStorage.getItem(`enhance-option_${lastPart}`);
  const designLibraryDisable = JSON.parse(
    localStorage.getItem(`designLibraryDisable_${lastPart}`)
  );

  const [defaultText, setDefaultText] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [defaultText3, setDefaultText3] = useState("");
  const [remaining3, setRemaining3] = useState(0);
  const [defaultText1, setDefaultText1] = useState("");
  const [remaining1, setRemaining1] = useState(0);
  const [defaultText2, setDefaultText2] = useState("");
  const [remaining2, setRemaining2] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [firstTierZeroCall, setFirstTierZeroCall] = useState();
  const [firstTierZeroNameCall, setFirstTierZeroNameCall] = useState();
  const [secondTierZeroCall, setSecondTierZeroCall] = useState();
  const [secondTierZeroNameCall, setSecondTierZeroNameCall] = useState();
  const [thirdTierZeroCall, setThirdTierZeroCall] = useState();
  const [thirdTierZeroNameCall, setThirdTierZeroNameCall] = useState();
  const [thirdTierZeroSubNameCall, setThirdTierZeroSubNameCall] = useState();
  const [privateModelError, setPrivateModelError] = useState(false);
  const [checked, setChecked] = useState(promptLocalData?.isTemplate ?? true);
  const [privateModelData, setPrivateModelData] = useState([]);
  const [isModelDataLoad, setIsModelDataLoad] = useState(false);
  const [modelSelected, setModelSelected] = useState();
  const [value, setValue] = useState();
  const [activeFidelityLevel, setActiveFidelityLevel] = useState([5, 5]);
  const [inputKey, setInputKey] = useState(0);
  const [inputKey1, setInputKey1] = useState(0);
  const [isToolset, setIsToolset] = useState(0);
  const [isIndex, setIsIndex] = useState(0);
  const [isTemplate, setIsTemplate] = useState(
    istemplateLocal?.isTemplate ?? true
  );
  const [isAtmosphere, setIsAtmosphere] = useState(
    istemplateLocal?.isAtmosphere ?? false
  );
  const [isPrompt, setIsPrompt] = useState(istemplateLocal?.isPrompt ?? false);
  const [isPrivate, setIsPrivate] = useState(
    istemplateLocal?.isPrivate ?? false
  );
  const [apiCameraData, setApiCameraData] = useState([]);
  const [apiGraphicData, setApiGraphicData] = useState([]);
  const [typing, setTyping] = useState(false);
  const isLogin =
    isLoginUser !== "undefined"
      ? JSON.parse(isLoginUser)
      : localStorage.setItem("loginUser", false);

  useEffect(() => {
    if (atmosphereRefLocal === null || atmosphereRefLocal === undefined) {
      const check = [
        {
          refFidelityLevel: 5,
          feedDesignBase64: null,
          uploadedBaseImageUrl: null,
        },
        {
          refFidelityLevel: 5,
          feedDesignBase64: null,
          uploadedBaseImageUrl: null,
        },
      ];
      localStorage.setItem(
        `atmosphereRefLocal_${lastPart}`,
        JSON.stringify(check)
      );
      // updateAtmosphereRefData(check);
    } else {
      setActiveFidelityLevel([
        atmosphereRefLocal[0]?.refFidelityLevel,
        atmosphereRefLocal[1]?.refFidelityLevel,
      ]);
    }
    if (istemplateLocal == null || istemplateLocal === undefined) {
      const check = {
        isTemplate: true,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };

      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
    }

    if (!designLibraryDisable) {
      const check = {
        isDesign: false,
      };

      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    }
  }, []);

  useEffect(() => {
    if (
      tiresData?.isFirstTierId === null ||
      tiresData?.isFirstTierId === undefined
    ) {
      callAllTiers();
    }
  }, [tiresData]);

  useEffect(() => {
    if (typing) return;

    if (
      (promptLocalData?.additionalPrompt === null ||
        promptLocalData?.additionalPrompt === undefined ||
        promptLocalData?.prompt === null ||
        promptLocalData?.prompt === undefined) &&
      (promptLocalData?.negativePrompt === null ||
        promptLocalData?.negativePrompt === undefined) &&
      (promptLocalData?.privateModelValue === null ||
        promptLocalData?.privateModelValue === undefined)
    ) {
      const data = {
        isTemplate: checked,
        additionalPrompt: "",
        prompt: "",
        negativePrompt: "",
        privateModel: null,
        privateModelCh: null,
        privateModelValue: null,
      };
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(data));
    } else {
      if (
        promptLocalData?.prompt !== "" &&
        promptLocalData?.prompt !== undefined &&
        promptLocalData?.prompt !== null
      ) {
        handleTextAreaChange(promptLocalData?.prompt);
      } else {
        handleTextAreaChange("");
      }
      if (
        promptLocalData?.negativePrompt !== "" &&
        promptLocalData?.negativePrompt !== undefined &&
        promptLocalData?.negativePrompt !== null
      ) {
        handleTextAreaChange1(promptLocalData?.negativePrompt);
      } else {
        handleTextAreaChange1("");
      }
      if (
        promptLocalData?.privateModelValue !== "" &&
        promptLocalData?.privateModelValue !== undefined &&
        promptLocalData?.privateModelValue !== null
      ) {
        setValue(promptLocalData?.privateModelValue);
      }
    }
  }, [promptLocalData]);

  useEffect(() => {
    privateModelApiRef.current = false;
    if (!privateModelApiRef.current) {
      privateModelApiRef.current = true;
      getAllPrivateModel();
    }
  }, [isTeamChanges]);

  const getAllPrivateModel = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    GetAllPrivateModel((res) => {
      if (res?.status === 200) {
        const options = res?.data.map((model) => ({
          value: model?.id,
          label: model?.modelName,
          defaultStrength: model?.defaultStrength,
          modelDescription: model?.modelDescription,
          modelName: model?.modelName,
          modelNameAlias: model?.modelNameAlias,
          modelPrompt: model?.modelPrompt,
          priority: model?.priority,
        }));
        const optionsCh = res?.data.map((model) => ({
          value: model?.id,
          label: model?.modelNameAlias,
          defaultStrength: model?.defaultStrength,
          modelDescription: model?.modelDescription,
          modelName: model?.modelName,
          modelNameAlias: model?.modelNameAlias,
          modelPrompt: model?.modelPrompt,
          priority: model?.priority,
        }));
        setPrivateModelData((lang === "ch" ? optionsCh : options) ?? []);
        const check =
          JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
        if (check?.privateModel === undefined || check?.privateModel === null) {
          check.privateModel = options[0];
          check.privateModelCh = optionsCh[0];
          setModelSelected(lang === "ch" ? optionsCh[0] : options[0]);
        }
        localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      }
    }, teamId);
  };

  useEffect(() => {
    if (istemplateLocal?.isAtmosphere !== undefined) {
      if (istemplateLocal?.isAtmosphere !== isAtmosphere) {
        setIsAtmosphere(istemplateLocal?.isAtmosphere);
      } else if (istemplateLocal?.isTemplate !== isTemplate) {
        setIsTemplate(istemplateLocal?.isTemplate);
      } else if (istemplateLocal?.isPrompt !== isPrompt) {
        setIsPrompt(istemplateLocal?.isPrompt);
      } else if (istemplateLocal?.isPrivate !== isPrivate) {
        setIsPrivate(istemplateLocal?.isPrivate);
      }
    }
  }, [istemplateLocal]);

  const handleTemplate = (val) => {
    let check =
      JSON.parse(localStorage.getItem(`isTemplate_${lastPart}`)) || {};
    if (check?.isAtmosphere !== undefined) {
      if (val === 1) {
        setIsAtmosphere(!check?.isAtmosphere ?? !isAtmosphere);
        check.isAtmosphere = !check?.isAtmosphere ?? !isAtmosphere;
      } else if (val === 2) {
        setIsTemplate(!check?.isTemplate ?? !isTemplate);
        check.isTemplate = !check?.isTemplate ?? !isTemplate;
      } else if (val === 3) {
        setIsPrompt(!check?.isPrompt ?? !isPrompt);
        check.isPrompt = !check?.isPrompt ?? !isPrompt;
      } else if (val === 4) {
        setIsPrivate(!check?.isPrivate ?? !isPrivate);
        check.isPrivate = !check?.isPrivate ?? !isPrivate;
      }
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
    }
  };

  const callAllTiers = async () => {
    try {
      const firstTierZeroCall = await callFirstFier();
      const secondTierZeroCall = await callSecondFier(firstTierZeroCall.id);
      const thirdTierZeroCall = await callThirdFier(secondTierZeroCall.id);
      if (secondTierZeroCall) {
        const payload = {
          secondTierId: secondTierZeroCall.id || secondTierZeroCall,
        };
        const cameraData = JSON.parse(
          localStorage.getItem(`cameraData_${lastPart}`)
        );
        if (cameraData === undefined || cameraData === null) {
          if (payload?.secondTierId) {
            GetCameraViewUser((res) => {
              if (res?.status === 200 && res?.data?.length > 0) {
                if (res?.data?.length !== 0) {
                  setApiCameraData(res?.data);
                  const isData = {
                    cameraSelectedOption: res?.data[0],
                    cameraViewId: res?.data[0]?.id,
                    cameraViewName: res?.data[0]?.name,
                  };
                  localStorage.setItem(
                    `cameraData_${lastPart}`,
                    JSON.stringify(isData)
                  );
                  getAtmosphere();
                }
              }
            }, payload);
            GetGraphicStyleData((res) => {
              if (res?.status === 200 && res?.data?.length > 0) {
                if (res?.data?.length !== 0) {
                  setApiGraphicData(res?.data);
                  const isData = {
                    graphicSelectedOption: res?.data[0],
                    graphicId: res?.data[0]?.id,
                    graphicName: res?.data[0]?.name,
                  };
                  localStorage.setItem(
                    `graphicStyleData_${lastPart}`,
                    JSON.stringify(isData)
                  );
                  getAtmosphere();
                }
              }
            }, payload);
          }
        }
      }
      const isData = {
        firstTierName: firstTierZeroCall.name,
        firstTierNameCh: firstTierZeroCall.alias,
        isFirstTierId: firstTierZeroCall.id,
        secondTierName: secondTierZeroCall.name,
        secondTierNameCh: secondTierZeroCall.alias,
        isSecondTierId: secondTierZeroCall.id,
        thirdTierName: thirdTierZeroCall.style[0].tierThreeName,
        thirdTierNameCh: thirdTierZeroCall.style[0].tierThreeNameAlias,
        isThirdTierId: thirdTierZeroCall.style[0].styleType,
        thirdTierSubName: thirdTierZeroCall.style[0].name,
        thirdTierSubNameCh: thirdTierZeroCall.style[0].alias,
        styleId: thirdTierZeroCall.style[0].id,
        styleURL: thirdTierZeroCall.style[0].coverImageSuffix,
      };
      localStorage.setItem(
        `designLibraryData_${lastPart}`,
        JSON.stringify(isData)
      );
      return {
        firstTierZeroCall,
        secondTierZeroCall,
        thirdTierZeroCall,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const callFirstFier = () => {
    return new Promise((resolve) => {
      const payload = {
        designLibraryId: 22,
      };
      if (payload) {
        GetFirstTierUser((res) => {
          const firstTierData = res?.data?.[0];
          if (firstTierData) {
            setFirstTierZeroCall(firstTierData.id);
            setFirstTierZeroNameCall(firstTierData.name);
            resolve(firstTierData);
          } else {
            resolve({});
          }
        }, payload);
      }
    });
  };

  const callSecondFier = (firstTierZeroCall) => {
    return new Promise((resolve) => {
      const payload = {
        designLibraryId: 22,
        firstTierId: firstTierZeroCall,
      };
      if (payload) {
        GetSecondTierUser((res) => {
          const secondTierData = res?.data?.[0];
          if (secondTierData) {
            setSecondTierZeroCall(secondTierData?.id);
            setSecondTierZeroNameCall(secondTierData?.name);
            resolve(secondTierData);
          } else {
            resolve({});
          }
        }, payload);
      }
    });
  };

  const callThirdFier = (secondTierZeroCall) => {
    return new Promise((resolve) => {
      const payload = {
        secondTierId: secondTierZeroCall,
      };
      if (payload?.secondTierId) {
        GetThirdTierAndStyleUser((res) => {
          if (res?.status === 200 && res?.data?.length !== 0) {
            const firstObjectData = res?.data?.[0];
            setThirdTierZeroCall(firstObjectData.id);
            setThirdTierZeroNameCall(firstObjectData.tierThreeName);
            setThirdTierZeroSubNameCall(firstObjectData.name);
            resolve(firstObjectData);
          }
        }, payload);
      }
    });
  };

  const getAtmosphere = () => {
    if (
      atmosphereData?.defaultSelectedAtmosphereId === undefined ||
      atmosphereData?.defaultSelectedAtmosphereId === null
    ) {
      const designLibraryType = dlData?.prevDesignLibraryId ?? 22;
      GetCameraViewUrlUser((res) => {
        if (res?.status === 200 && res?.data?.length !== 0) {
          const atmosphere = {
            defaultSelectedAtmosphereId: res?.data?.[0]?.id,
            defaultSelectedAtmosphereUrl: res?.data?.[0]?.thumbnail,
            seasonName: "sunny",
            seasonIcon: sunny,
            seasonNameSelected: "spring",
            seasonNameSelectedId: 1,
          };
          localStorage.setItem(
            `atmosphereData_${lastPart}`,
            JSON.stringify(atmosphere)
          );
        }
      }, designLibraryType);
    }
  };

  useEffect(() => {
    if (
      designLibrary?.isDesignLibraryId === null ||
      designLibrary?.isDesignLibraryId === undefined
    ) {
      const data = {
        isDesignLibraryId: 22,
        prevDesignLibraryId: 22,
        designLibraryName: "Architecture",
      };
      localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(data));
    }
  }, [designLibrary]);

  const handleActiveTires = (e) => {
    const newData = {
      isCloseTiresPopup: !designLibraryData?.isCloseTiresPopup,
      isShowTrueOrFalse: false,
      isDisplayAlldata: false,
    };
    updateDesignLibraryData(newData);
    let DesignData =
      JSON.parse(localStorage.getItem(`dlData_${lastPart}`)) || {};
    DesignData.isDesignLibraryId = e;
    if (e === 22) {
      DesignData.designLibraryName = "Architecture";
    } else if (e === 30) {
      DesignData.designLibraryName = "Interior";
    } else if (e === 31) {
      DesignData.designLibraryName = "Landscape";
    } else if (e === 32) {
      DesignData.designLibraryName = "Urban design";
    } else {
      DesignData.designLibraryName = "No Style";
    }
    localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(DesignData));
  };

  const handleTiresPopup = () => {
    const newData = {
      isCloseTiresPopup: !designLibraryData?.isCloseTiresPopup,
      isShowTrueOrFalse: false,
      isDisplayAlldata: true,
    };
    updateDesignLibraryData(newData);
  };

  useEffect(() => {
    // Adjust textarea height based on its scroll height
    if (textareaRef.current) {
      textareaRef.current.classList.add("auto-resize");
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [defaultText]);

  useEffect(() => {
    // Adjust textarea height based on its scroll height
    if (textarea1Ref.current) {
      textarea1Ref.current.classList.add("auto-resize");
      textarea1Ref.current.style.height = "auto";
      textarea1Ref.current.style.height = `${textarea1Ref.current.scrollHeight}px`;
    }
  }, [defaultText1]);

  const handleTypingFocus = () => {
    if (!typing) {
      setTyping(true);
    }
  };

  const handleTypingBlur = () => {
    if (typing) {
      setTyping(false);
    }
  };

  const handleTextAreaChange = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText(inputText);
      setRemaining(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.prompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(inputText, 1500);
      setDefaultText(truncatedText);
      setRemaining(Math.max(0, remainingChars));
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.prompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleTextAreaChange1 = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText1(inputText);
      setRemaining1(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.negativePrompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(inputText, 800);
      setDefaultText1(truncatedText);
      setRemaining1(Math.max(0, remainingChars));

      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.negativePrompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleChange = (e) => {
    const check =
      JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
    if (check?.privateModel !== undefined || check?.privateModel !== null) {
      const option = {
        value: e?.value,
        label: e?.modelName,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      const optionCh = {
        value: e?.value,
        label: e?.modelNameAlias,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      check.privateModel = option;
      check.privateModelCh = optionCh;
      check.privateModelValue = e.defaultStrength;
      setModelSelected(lang === "ch" ? optionCh : option);
      setValue(e?.defaultStrength);
    } else {
      const option = {
        value: e?.value,
        label: e?.modelName,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      const optionCh = {
        value: e?.value,
        label: e?.modelNameAlias,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      check.privateModel = option;
      check.privateModelCh = optionCh;
      check.privateModelValue = e.defaultStrength;
      setModelSelected(lang === "ch" ? optionCh : option);
      setValue(e?.defaultStrength);
    }
    localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
  };

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      width: "100%",
      borderColor: "#263238",
      backgroundColor: "#f8f8f9",
      borderRadius: "8px",
      position: "absolute",
      top: "-210%",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: responsiveData ? "12px" : "14px",
      backgroundColor: state.isSelected ? "#ffffff" : "#F8F8F9",
      color: state.isSelected ? "#677074" : "#A8ADAF",
    }),
  };

  const handleScroll = (event) => {
    const delta = Math.sign(event.deltaY);
    const newValue =
      delta > 0
        ? Math.max(parseFloat((value - 0.1).toFixed(1)), 0.1)
        : Math.min(parseFloat((value + 0.1).toFixed(1)), 1);

    const check =
      JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
    check.privateModelValue = newValue;
    localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    setValue(newValue);
  };

  const handleClickOpenClose = () => {
    const newData = {
      isShowTrueOrFalse: true,
      isCloseTiresPopup: false,
    };
    updateDesignLibraryData(newData);
  };

  const handleChange1 = (event) => {
    const inputValue = parseFloat(event.target.value);
    if (isNaN(inputValue)) {
      setValue();
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = "";
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }

    const parsedValue = parseFloat(inputValue);

    if (parsedValue === 0) {
      setValue(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = 0;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 1) {
      const roundedValue = parseFloat(parsedValue.toFixed(1));
      setValue(roundedValue);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = roundedValue;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      setValue(check.privateModelValue);
    }
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 0:
      case 11:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check[0]) {
          check[0].feedDesignBase64 = null;
          check[0].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
        setInputKey((prevKey) => prevKey + 1);
        break;
      case 12:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check1 = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check1[1]) {
          check1[1].feedDesignBase64 = null;
          check1[1].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check1)
        );
        setInputKey1((prevKey) => prevKey + 1);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (toolset, event) => {
    if (!event) {
      console.log(event);
      return null;
    }
    const files = event.target.files || event.dataTransfer.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return null;
    }
    setIsButtonDisabled(true);
    const file = files[0];
    if (!file) {
      setIsButtonDisabled(false);
      return null;
    }

    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    if (validTypes.includes(file.type)) {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    } else {
      alert("Please upload an image in JPG or PNG format");
      return null;
    }
  };

  const handleFileChangeWithToolsetInput = (event, toolset) => {
    const imageType = "reference-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    setInputKey((prevKey) => prevKey + 1);
    const imageUrl = handleFileChange(toolset, event);
    if (imageUrl) {
      updatePreUploadState(toolset, imageUrl);
      handleBaseImageData(toolset, imageUrl);
    }
  };

  const handleFileChangeWithToolsetInput1 = (event, toolset) => {
    setInputKey1((prevKey) => prevKey + 1);
    const imageUrl = handleFileChange(toolset, event);
    if (imageUrl) {
      updatePreUploadState(toolset, imageUrl);
      handleBaseImageData(toolset, imageUrl);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, toolset = null) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedToolset =
      toolset !== null && toolset !== undefined ? toolset : isToolset;
    const imageType = "reference-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    if (event.dataTransfer.files?.length === 0) {
      const media = JSON.parse(event.dataTransfer.getData("media"));
      const updatedLink = media?.url;
      updatePreUploadState(selectedToolset, updatedLink);
      CallAwsUrl((res) => {
        handleBaseImageData(selectedToolset, handleBase64Data(res.data));
      }, updatedLink);
    } else if (event.dataTransfer.files?.length === 1) {
      //TODO:what is this for? delete?
      const imageUrl = handleFileChange(selectedToolset, event);
      if (imageUrl) {
        updatePreUploadState(selectedToolset, imageUrl);
        handleBaseImageData(selectedToolset, imageUrl);
      }
    }
  };

  const handleBaseImageData = (toolset, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let finalResizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }
      const proceedNextStep = () => {
        fetch(finalResizedImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedImageFile = new File([blob], "resized_image.jpg", {
              type: "image/jpg",
            });

            uploadFile(
              "reference-image",
              resizedImageFile,
              async (data) => {
                switch (toolset) {
                  case 0:
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = data;
                      check[0].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check1[1]) {
                      check1[1].feedDesignBase64 = data;
                      check1[1].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    setInputKey1((prevKey) => prevKey + 1);
                    break;
                  default:
                    break;
                }
              },
              () => setIsButtonDisabled(false),
              (error) => {
                handleRateLimitPopup(error);
                switch (toolset) {
                  case 0:
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = null;
                      check[0].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check1[1]) {
                      check1[1].feedDesignBase64 = null;
                      check1[1].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    setInputKey1((prevKey) => prevKey + 1);
                    break;
                  default:
                    break;
                }
              }
            );
          })
          .catch((error) => {
            console.error("Error converting Data URL to File:", error);
          });
      };

      proceedNextStep();
    };
  };

  const handleRateLimitPopup = (error) => {
    updateAtmosphereRefData({ uploadedBaseImageUrl: null });
    const renderUpdate = {
      uploadedBaseImageUrl: null,
    };
    localStorage.setItem(
      `atmosphereRefLocal_${lastPart}`,
      JSON.stringify(renderUpdate)
    );
    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  useEffect(() => {
    if (atmosphereRefLocal?.[0]?.refFidelityLevel) {
      setActiveFidelityLevel((prevLevels) => {
        const newLevels = { ...prevLevels };
        newLevels[0] = atmosphereRefLocal?.[0]?.refFidelityLevel;
        return newLevels;
      });
    }
  }, [atmosphereRefLocal?.[0]?.refFidelityLevel]);

  const handleImageSliderChange = (value, index) => {
    let check = JSON.parse(
      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
    );
    if (check !== undefined && check !== null) {
      if (check[isIndex]?.refFidelityLevel) {
        if (value) {
          check[isIndex].refFidelityLevel = value;
          setActiveFidelityLevel((prevLevels) => {
            const newLevels = { ...prevLevels };
            newLevels[index] = value;
            return newLevels;
          });
        } else if (value === 0) {
          check[isIndex].refFidelityLevel = value;
          setActiveFidelityLevel((prevLevels) => {
            const newLevels = { ...prevLevels };
            newLevels[index] = value;
            return newLevels;
          });
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
      } else if (check[isIndex]?.refFidelityLevel === 0) {
        if (value) {
          check[isIndex].refFidelityLevel = value;
          setActiveFidelityLevel((prevLevels) => {
            const newLevels = { ...prevLevels };
            newLevels[index] = value;
            return newLevels;
          });
        } else if (value === 0) {
          check[isIndex].refFidelityLevel = value;
          setActiveFidelityLevel((prevLevels) => {
            const newLevels = { ...prevLevels };
            newLevels[index] = value;
            return newLevels;
          });
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
      }
    }
    setIsIndex(index);
  };

  const handleDeleteRef = (id) => {
    const check = JSON.parse(
      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
    );
    if (check[id]) {
      check[id].feedDesignBase64 = null;
      check[id].uploadedBaseImageUrl = null;
    }
    localStorage.setItem(
      `atmosphereRefLocal_${lastPart}`,
      JSON.stringify(check)
    );
    setInputKey((prevKey) => prevKey + 1);
    setInputKey1((prevKey) => prevKey + 1);
  };

  const handleActiveUpscale = () =>{
    const newToolsetData = {
      toolsetActive: 3,
    };
    setIsActiveToolset(3);
    localStorage.setItem(
      `activeToolset_${lastPart}`,
      JSON.stringify(newToolsetData)
    );
  }

  return (
    <div className="archi" key={templateDisable}>
      <div
        className={`archi__container ${
          templateDisable ? "disable" : ""
        }`}
      >
        <div className="archi__sections">
          <div className="archi__section">
            <div className="archi__section__main">
              <div
                className="archi__section__main__heading"
                style={{ position: "relative" }}
              >
                <h4>{t("design_library")}</h4>
              </div>
            </div>
            <div className="archi__section__main-new" data-scrollable="true">
              <div className="archi__section__main__sub__main">
                <div
                  className={`archi__section__main__sub__heading ${
                    istemplateLocal?.isTemplate ? "" : "off"
                  }`}
                  onClick={() => handleTemplate(2)}
                >
                  <div className="archi__section__main__sub__heading__left">
                    <h4>{t("Template")}</h4>
                    <div
                      className="tooltip-top"
                      onClick={(event) => handleActiveLearn(21, event)}
                    >
                      {!responsiveData && <img src={intro} alt="intro" />}
                      <Portal
                        style={{
                          display: `${
                            activeLearnBool?.boolean === true &&
                            activeLearnBool?.id === 21
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {!responsiveData && (
                          <TipsPopups1
                            heading={t("Template")}
                            content={t("template_content")}
                            styles={lang === "ch" ? "340px" : "400px"}
                            image={template}
                            position={activeLearnBool?.position}
                          />
                        )}
                      </Portal>
                    </div>
                  </div>
                  <div className="archi__section__main__sub__heading__right">
                    <Switch
                      onChange={() => handleTemplate(2)}
                      checked={
                        istemplateLocal?.isTemplate !== undefined
                          ? istemplateLocal?.isTemplate
                          : false
                      }
                      height={responsiveData ? 14 : 16}
                      width={responsiveData ? 28 : 32}
                      handleDiameter={12}
                      offColor="#A8ADAF"
                      onColor="#000"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className={`${
                        responsiveData
                          ? istemplateLocal?.isTemplate !== undefined &&
                            istemplateLocal?.isTemplate
                            ? "small-on "
                            : "small-off "
                          : ""
                      }react-switch-Undragable`}
                    />
                  </div>
                </div>
                {(istemplateLocal?.isTemplate ?? isTemplate) && (
                  <div className="archi__section__main__sub">
                    <div
                      className={`archi__section__main__sub__sec ${
                        designLibraryData?.isCloseTiresPopup === true &&
                        designLibraryData?.isDisplayAlldata === false
                          ? designLibrary?.isDesignLibraryId === 22 && "active"
                          : designLibrary?.prevDesignLibraryId === 22 &&
                            "active"
                      }`}
                      onClick={() => handleActiveTires(22)}
                    >
                      <img src={archib} alt="Archi-icon" />
                      <NavLink>{t("archi")}</NavLink>
                    </div>
                    <div
                      className={`archi__section__main__sub__sec ${
                        designLibraryData?.isCloseTiresPopup === true &&
                        designLibraryData?.isDisplayAlldata === false
                          ? designLibrary?.isDesignLibraryId === 30 && "active"
                          : designLibrary?.prevDesignLibraryId === 30 &&
                            "active"
                      }`}
                      onClick={() => handleActiveTires(30)}
                    >
                      <img src={interior} alt="Interior-icon" />
                      <NavLink>{t("interior")}</NavLink>
                    </div>
                    <div
                      className={`archi__section__main__sub__sec ${
                        designLibraryData?.isCloseTiresPopup === true &&
                        designLibraryData?.isDisplayAlldata === false
                          ? designLibrary?.isDesignLibraryId === 31 && "active"
                          : designLibrary?.prevDesignLibraryId === 31 &&
                            "active"
                      }`}
                      onClick={() => handleActiveTires(31)}
                    >
                      <img src={landsc} alt="Landscape-icon" />
                      <NavLink>{t("landsc")}</NavLink>
                    </div>
                    <div
                      className={`archi__section__main__sub__sec ${
                        designLibraryData?.isCloseTiresPopup === true &&
                        designLibraryData?.isDisplayAlldata === false
                          ? designLibrary?.isDesignLibraryId === 32 && "active"
                          : designLibrary?.prevDesignLibraryId === 32 &&
                            "active"
                      }`}
                      onClick={() => handleActiveTires(32)}
                    >
                      <img src={urbanDesign} alt="graphic-icon" />
                      <NavLink>{t("urbanDesign_name")}</NavLink>
                    </div>
                  </div>
                )}
              </div>
              {(istemplateLocal?.isTemplate ?? isTemplate) && (
                <div
                  className="archi__section__main"
                  style={{ display: checked ? "flex" : "none" }}
                >
                  <div
                    className="archi__section__main__image"
                    onClick={handleTiresPopup}
                  >
                    {tiresData?.styleURL ? (
                      <img src={tiresData?.styleURL} alt={tiresData?.styleId} />
                    ) : (
                      (
                        <img
                          src="https://airi-assets.s3.cn-north-1.amazonaws.com.cn/ciTemp/22/1/1/1.jpg"
                          alt="defalut"
                        />
                      ) ?? (
                        <>
                          <img src={defaultimg} alt="default-img" />
                          <img
                            src={defaulticon}
                            alt="default-img"
                            className="icon"
                          />
                        </>
                      )
                    )}
                    <div className="archi__section__main__content">
                      {!tiresData?.styleURL ||
                      tiresData?.styleURL !== undefined ? (
                        <>
                          <div className="archi__section__main__content__sec">
                            {lang === "ch" ? (
                              <span>
                                {tiresData?.secondTierNameCh || "高层"}
                              </span>
                            ) : (
                              <span>
                                {tiresData?.secondTierName || "High-rise"}
                              </span>
                            )}
                          </div>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                  <div className="archi__section__main__sec1">
                    <div className="archi__section__main__graphic">
                      <div
                        className="archi__section__main__graphic__heading"
                        style={{ position: "relative" }}
                      >
                        <h4>{t("graphic_style")}</h4>
                        {/* {!responsiveData && (
                          <div
                            className="tooltip-top"
                            onClick={(e) => handleActiveLearn(222, e)}
                          >
                            <img src={intro} alt="intro" />
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 222
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <TipsPopups1
                                heading={t("graphic_style")}
                                content={t("graphic_style_content")}
                                styles="290px"
                                image={atmosphereimage}
                                position={activeLearnBool?.position}
                              />
                            </Portal>
                          </div>
                        )} */}
                      </div>
                      <div className="archi__section__main__graphic__section">
                        <GraphicStyleDropDown data={apiGraphicData} />
                      </div>
                    </div>
                    <div className="archi__section__main__camera">
                      <div
                        className="archi__section__main__camera__heading"
                        style={{ position: "relative" }}
                      >
                        <h4>{t("camera")}</h4>
                        {!responsiveData && (
                          <div
                            className="tooltip-top"
                            onClick={(e) => handleActiveLearn(1, e)}
                          >
                            <img src={intro} alt="intro" />
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 1
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <TipsPopups1
                                heading={t("camera")}
                                content={t("camera_tooltip_design")}
                                styles="334px"
                                image={cameraimage}
                                position={activeLearnBool?.position}
                              />
                            </Portal>
                          </div>
                        )}
                      </div>
                      <div className="archi__section__main__camera__section">
                        <CameraDropDown data={apiCameraData} />
                      </div>
                    </div>
                    <div className="archi__section__main__atmosphere">
                      <div
                        className="archi__section__main__atmosphere__heading"
                        style={{ position: "relative" }}
                      >
                        <h4>{t("atmosphere")}</h4>
                        {!responsiveData && (
                          <div
                            className="tooltip-top"
                            onClick={(e) => handleActiveLearn(2, e)}
                          >
                            <img src={intro} alt="intro" />
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 2
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <TipsPopups1
                                heading={t("atmosphere")}
                                content={t("atmosphere_tooltip")}
                                styles="290px"
                                image={atmosphereimage}
                                position={activeLearnBool?.position}
                              />
                            </Portal>
                          </div>
                        )}
                      </div>
                      {dlData?.prevDesignLibraryId === 99 ? (
                        <div
                          className="archi__section__main__atmosphere__sec"
                          // onClick={handleClickOpenClose}
                          onClick={null}
                        >
                          <div className="archi__section__main__atmosphere__sec__sub">
                            <div className="archi__section__main__atmosphere__sec__sub__section">
                              <div
                                className="archi__section__main__atmosphere__sec__sub__section__atom"
                                style={{
                                  cursor: "default",
                                  background: "white",
                                  border: "1px solid #EDEDED",
                                }}
                              >
                                <span>{t("graphic")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="archi__section__main__atmosphere__sec"
                          onClick={handleClickOpenClose}
                        >
                          <div className="archi__section__main__atmosphere__sec__sub">
                            <div className="archi__section__main__atmosphere__sec__sub__section">
                              <div className="archi__section__main__atmosphere__sec__sub__section__atom">
                                <img
                                  src={atmosphere?.seasonIcon || sunny}
                                  alt={
                                    atmosphere?.seasonNameSelected || "sunny"
                                  }
                                />
                                <span>
                                  {t(atmosphere?.seasonNameSelected || "sunny")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div
                className="archi__section__atmosphere"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className={`archi__section__atmosphere__heading ${
                    !istemplateLocal?.isAtmosphere ? "off" : ""
                  }`}
                  onClick={() => handleTemplate(1)}
                >
                  <div
                    className="archi__section__atmosphere__heading__left"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("atmosphere_shape_ref")}</h4>
                    <div
                      className="tooltip-top"
                      onClick={(event) => handleActiveLearn(23, event)}
                    >
                      {!responsiveData && <img src={intro} alt="intro" />}
                      <Portal
                        style={{
                          display: `${
                            activeLearnBool?.boolean === true &&
                            activeLearnBool?.id === 23
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {!responsiveData && (
                          <TipsPopups1
                            heading={t("atmosphere_shape_ref")}
                            content={t("Reference_content")}
                            styles="320px"
                            image={stylerefimage}
                            position={activeLearnBool?.position}
                          />
                        )}
                      </Portal>
                    </div>
                  </div>
                  <div className="archi__section__atmosphere__heading__right">
                    <Switch
                      onChange={() => handleTemplate(1)}
                      checked={
                        istemplateLocal?.isAtmosphere !== undefined
                          ? istemplateLocal?.isAtmosphere
                          : false
                      }
                      height={responsiveData ? 14 : 16}
                      width={responsiveData ? 28 : 32}
                      handleDiameter={12}
                      offColor="#A8ADAF"
                      onColor="#000"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className={`${
                        responsiveData
                          ? istemplateLocal?.isAtmosphere !== undefined &&
                            istemplateLocal?.isAtmosphere
                            ? "small-on "
                            : "small-off "
                          : ""
                      }react-switch-Undragable`}
                    />
                  </div>
                </div>
                {(istemplateLocal?.isAtmosphere ?? isAtmosphere) && (
                  <>
                    <div className="archi__section__atmosphere__image">
                      <div className="archi__section__atmosphere__image__base">
                        <div
                          className="card"
                          onDragOver={(e) => {
                            handleDragOver(e);
                            setIsToolset(11);
                          }}
                          onDrop={(e) => {
                            handleDrop(e);
                            setIsToolset(11);
                          }}
                          onClick={() => setIsToolset(11)}
                          key={inputKey}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInputRef"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileChangeWithToolsetInput(event, 11)
                            }
                          />
                          {atmosphereRefLocal !== undefined &&
                            atmosphereRefLocal !== null && (
                              <>
                                {!atmosphereRefLocal[0]?.feedDesignBase64 ? (
                                  <label
                                    htmlFor="fileInputRef"
                                    key={inputKey}
                                    style={{
                                      cursor:
                                        atmosphereRefLocal[0]
                                          ?.uploadedBaseImageUrl &&
                                        !atmosphereRefLocal[0]?.feedDesignBase64
                                          ? "progress"
                                          : "pointer",
                                    }}
                                  >
                                    <div
                                      className="card-content"
                                      onDragOver={(e) => {
                                        handleDragOver(e);
                                        setIsToolset(11);
                                      }}
                                      onDrop={(e) => {
                                        handleDrop(e);
                                        setIsToolset(11);
                                      }}
                                      style={{
                                        justifyContent: `${
                                          atmosphereRefLocal[0]
                                            ?.uploadedBaseImageUrl
                                            ? "Center"
                                            : "FlexEnd"
                                        }`,
                                        padding: `${
                                          atmosphereRefLocal[0]
                                            ?.uploadedBaseImageUrl
                                            ? "0"
                                            : lang === "ch"
                                            ? "0 0 8px 0"
                                            : "0 0 8px 0"
                                        }`,
                                      }}
                                    >
                                      {atmosphereRefLocal[0]
                                        ?.uploadedBaseImageUrl ? (
                                        <>
                                          <img
                                            className="uploaded"
                                            src={
                                              atmosphereRefLocal[0]
                                                ?.uploadedBaseImageUrl
                                            }
                                            draggable="false"
                                            alt=""
                                          />
                                          <div
                                            className="ref-delete"
                                            onClick={() => handleDeleteRef(0)}
                                          >
                                            <img
                                              className={`${
                                                atmosphereRefLocal[0]
                                                  ?.feedDesignBase64
                                                  ? "active"
                                                  : ""
                                              }`}
                                              src={refDelete}
                                              alt="refDelete"
                                            />
                                          </div>
                                          {atmosphereRefLocal[0]
                                            ?.feedDesignBase64 === null ||
                                          atmosphereRefLocal[0]
                                            ?.feedDesignBase64 === undefined ? (
                                            <>
                                              <div className="gradient-mask"></div>
                                              <div className="text-display">
                                                {t("uploading...")}
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="icon"
                                            src={baseicon}
                                            alt=""
                                          />
                                          <p>{t("upload_ref_image")}</p>
                                        </>
                                      )}
                                    </div>
                                  </label>
                                ) : (
                                  <label
                                    key={inputKey}
                                    style={{
                                      cursor:
                                        atmosphereRefLocal[0]
                                          ?.uploadedBaseImageUrl &&
                                        !atmosphereRefLocal[0]?.feedDesignBase64
                                          ? "progress"
                                          : "pointer",
                                    }}
                                  >
                                    <div
                                      className="card-content"
                                      onDragOver={(e) => {
                                        handleDragOver(e);
                                        setIsToolset(11);
                                      }}
                                      onDrop={(e) => {
                                        handleDrop(e);
                                        setIsToolset(11);
                                      }}
                                      style={{
                                        justifyContent: `${
                                          atmosphereRefLocal[0]
                                            ?.uploadedBaseImageUrl
                                            ? "Center"
                                            : "FlexEnd"
                                        }`,
                                        padding: `${
                                          atmosphereRefLocal[0]
                                            ?.uploadedBaseImageUrl
                                            ? "0"
                                            : "0 0 12px 0"
                                        }`,
                                      }}
                                    >
                                      {atmosphereRefLocal[0]
                                        ?.uploadedBaseImageUrl ? (
                                        <>
                                          <img
                                            className="uploaded"
                                            src={
                                              atmosphereRefLocal[0]
                                                ?.uploadedBaseImageUrl
                                            }
                                            draggable="false"
                                            alt=""
                                          />
                                          <div
                                            className="ref-delete"
                                            onClick={() => handleDeleteRef(0)}
                                          >
                                            <img
                                              className={`${
                                                atmosphereRefLocal[0]
                                                  ?.feedDesignBase64
                                                  ? "active"
                                                  : ""
                                              }`}
                                              src={refDelete}
                                              alt="refDelete"
                                            />
                                          </div>
                                          {atmosphereRefLocal[0]
                                            ?.feedDesignBase64 === null ||
                                          atmosphereRefLocal[0]
                                            ?.feedDesignBase64 === undefined ? (
                                            <>
                                              <div className="gradient-mask"></div>
                                              <div className="text-display">
                                                {t("uploading...")}
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="icon"
                                            src={baseicon}
                                            alt=""
                                          />
                                          <p>{t("upload_ref_image")}</p>
                                        </>
                                      )}
                                    </div>
                                  </label>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                      {atmosphereRefLocal !== undefined &&
                        atmosphereRefLocal !== null && (
                          <div className="archi__section__atmosphere__image__weight">
                            <Slider
                              // key={activeFidelityLevel[0]}
                              value={
                                activeFidelityLevel[0] ??
                                atmosphereRefLocal[0]?.refFidelityLevel
                              }
                              step={1}
                              min={1}
                              max={9}
                              onChange={(e) => handleImageSliderChange(e, 0)}
                            />
                            <span>
                              {activeFidelityLevel[0] ??
                                atmosphereRefLocal[0]?.refFidelityLevel}
                            </span>
                          </div>
                        )}
                    </div>
                    {/* <div className="archi__section__atmosphere__image">
                      <div className="archi__section__atmosphere__image__base">
                        {isLogin === false ? (
                          <div
                            className="card"
                            style={{
                              cursor: "not-allowed",
                            }}
                          >
                            <input style={{ display: "none" }} />
                            {atmosphereRefLocal !== undefined &&
                              atmosphereRefLocal !== null && (
                                <label
                                  htmlFor="fileInputRef1"
                                  style={{
                                    cursor: "not-allowed",
                                  }}
                                >
                                  <div
                                    className="card-content"
                                    style={{
                                      justifyContent: `${
                                        atmosphereRefLocal[1]
                                          ?.uploadedBaseImageUrl
                                          ? "Center"
                                          : "FlexEnd"
                                      }`,
                                      padding: `${
                                        atmosphereRefLocal[1]
                                          ?.uploadedBaseImageUrl
                                          ? "0"
                                          : "0 0 12px 0"
                                      }`,
                                    }}
                                  >
                                    {atmosphereRefLocal[1]
                                      ?.uploadedBaseImageUrl ? (
                                      <>
                                        <img
                                          className="uploaded"
                                          src={
                                            atmosphereRefLocal[1]
                                              ?.uploadedBaseImageUrl
                                          }
                                          draggable="false"
                                          alt=""
                                        />
                                        {atmosphereRefLocal[1]
                                          ?.feedDesignBase64 === null ||
                                        atmosphereRefLocal[1]
                                          ?.feedDesignBase64 === undefined ? (
                                          <>
                                            <div className="gradient-mask"></div>
                                            <div className="text-display">
                                              {t("uploading...")}
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="icon"
                                          src={baseicon}
                                          alt=""
                                        />
                                        <p>{t("upload_ref_image")}</p>
                                      </>
                                    )}
                                  </div>
                                </label>
                              )}
                          </div>
                        ) : (
                          <div
                            className="card"
                            onDragOver={(e) => {
                              handleDragOver(e);
                              setIsToolset(12);
                            }}
                            onDrop={(e) => {
                              handleDrop(e);
                              setIsToolset(12);
                            }}
                            onClick={() => setIsToolset(12)}
                          >
                            <input
                              key={inputKey1}
                              type="file"
                              accept=".jpeg, .jpg, .png, . webp"
                              id="fileInputRef1"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileChangeWithToolsetInput1(event, 12)
                              }
                            />
                            {atmosphereRefLocal !== undefined &&
                              atmosphereRefLocal !== null && (
                                <>
                                  {!atmosphereRefLocal[1]?.feedDesignBase64 ? (
                                    <label
                                      htmlFor="fileInputRef1"
                                      key={inputKey1}
                                      style={{
                                        cursor:
                                          atmosphereRefLocal[1]
                                            ?.uploadedBaseImageUrl &&
                                          !atmosphereRefLocal[1]
                                            ?.feedDesignBase64
                                            ? "progress"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        className="card-content"
                                        onDragOver={(e) => {
                                          handleDragOver(e);
                                          setIsToolset(12);
                                        }}
                                        onDrop={(e) => {
                                          handleDrop(e);
                                          setIsToolset(12);
                                        }}
                                        style={{
                                          justifyContent: `${
                                            atmosphereRefLocal[1]
                                              ?.uploadedBaseImageUrl
                                              ? "Center"
                                              : "FlexEnd"
                                          }`,
                                          padding: `${
                                            atmosphereRefLocal[1]
                                              ?.uploadedBaseImageUrl
                                              ? "0"
                                              : "0 0 12px 0"
                                          }`,
                                        }}
                                      >
                                        {atmosphereRefLocal[1]
                                          ?.uploadedBaseImageUrl ? (
                                          <>
                                            <img
                                              className="uploaded"
                                              src={
                                                atmosphereRefLocal[1]
                                                  ?.uploadedBaseImageUrl
                                              }
                                              draggable="false"
                                              alt=""
                                            />
                                            <div
                                              className="ref-delete"
                                              onClick={() => handleDeleteRef(1)}
                                            >
                                              <img
                                                className={`${
                                                  atmosphereRefLocal[1]
                                                    ?.feedDesignBase64 !== null
                                                    ? "active"
                                                    : ""
                                                }`}
                                                src={refDelete}
                                                alt="refDelete"
                                              />
                                            </div>
                                            {atmosphereRefLocal[1]
                                              ?.feedDesignBase64 === null ||
                                            atmosphereRefLocal[1]
                                              ?.feedDesignBase64 ===
                                              undefined ? (
                                              <>
                                                <div className="gradient-mask"></div>
                                                <div className="text-display">
                                                  {t("uploading...")}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="icon"
                                              src={baseicon}
                                              alt=""
                                            />
                                            <p>{t("upload_ref_image")}</p>
                                          </>
                                        )}
                                      </div>
                                    </label>
                                  ) : (
                                    <label
                                      key={inputKey1}
                                      style={{
                                        cursor:
                                          atmosphereRefLocal[1]
                                            ?.uploadedBaseImageUrl &&
                                          !atmosphereRefLocal[1]
                                            ?.feedDesignBase64
                                            ? "progress"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        className="card-content"
                                        onDragOver={(e) => {
                                          handleDragOver(e);
                                          setIsToolset(12);
                                        }}
                                        onDrop={(e) => {
                                          handleDrop(e);
                                          setIsToolset(12);
                                        }}
                                        style={{
                                          justifyContent: `${
                                            atmosphereRefLocal[1]
                                              ?.uploadedBaseImageUrl
                                              ? "Center"
                                              : "FlexEnd"
                                          }`,
                                          padding: `${
                                            atmosphereRefLocal[1]
                                              ?.uploadedBaseImageUrl
                                              ? "0"
                                              : "0 0 12px 0"
                                          }`,
                                        }}
                                      >
                                        {atmosphereRefLocal[1]
                                          ?.uploadedBaseImageUrl ? (
                                          <>
                                            <img
                                              className="uploaded"
                                              src={
                                                atmosphereRefLocal[1]
                                                  ?.uploadedBaseImageUrl
                                              }
                                              draggable="false"
                                              alt=""
                                            />
                                            <div
                                              className="ref-delete"
                                              onClick={() => handleDeleteRef(1)}
                                            >
                                              <img
                                                className={`${
                                                  atmosphereRefLocal[1]
                                                    ?.feedDesignBase64 !== null
                                                    ? "active"
                                                    : ""
                                                }`}
                                                src={refDelete}
                                                alt="refDelete"
                                              />
                                            </div>
                                            {atmosphereRefLocal[1]
                                              ?.feedDesignBase64 === null ||
                                            atmosphereRefLocal[1]
                                              ?.feedDesignBase64 ===
                                              undefined ? (
                                              <>
                                                <div className="gradient-mask"></div>
                                                <div className="text-display">
                                                  {t("uploading...")}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="icon"
                                              src={baseicon}
                                              alt=""
                                            />
                                            <p>{t("upload_ref_image")}</p>
                                          </>
                                        )}
                                      </div>
                                    </label>
                                  )}
                                </>
                              )}
                          </div>
                        )}
                      </div>
                      {atmosphereRefLocal !== undefined &&
                        atmosphereRefLocal !== null && (
                          <div
                            className="archi__section__atmosphere__image__weight"
                            key={atmosphereRefLocal[1]}
                          >
                            <Slider
                              defaultValue={
                                activeFidelityLevel[1] ||
                                atmosphereRefLocal[1]?.refFidelityLevel
                              }
                              step={1}
                              min={1}
                              max={9}
                              onChange={(e) => handleImageSliderChange(e, 1)}
                            />
                            <span>
                              {activeFidelityLevel[1] ||
                                atmosphereRefLocal[1]?.refFidelityLevel}
                            </span>
                          </div>
                        )}
                    </div> */}
                  </>
                )}
              </div>
              <div className="archi__section__main1">
                <div
                  className={`archi__section__main1__heading ${
                    istemplateLocal?.isPrompt ? "" : "off"
                  }`}
                  onClick={() => handleTemplate(3)}
                >
                  <div
                    className="archi__section__main1__heading__left"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("prompt")}</h4>
                    <div
                      className="tooltip-top"
                      onClick={(event) => handleActiveLearn(24, event)}
                    >
                      {!responsiveData && <img src={intro} alt="intro" />}
                      <Portal
                        style={{
                          display: `${
                            activeLearnBool?.boolean === true &&
                            activeLearnBool?.id === 24
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {!responsiveData && (
                          <TipsPopups1
                            heading={t("prompt")}
                            content={t("prompt_tooltip")}
                            styles="160px"
                            image={promptImage}
                            position={activeLearnBool?.position}
                          />
                        )}
                      </Portal>
                    </div>
                  </div>{" "}
                  <div className="archi__section__main1__heading__right">
                    <Switch
                      onChange={() => handleTemplate(3)}
                      checked={
                        istemplateLocal?.isPrompt !== undefined
                          ? istemplateLocal?.isPrompt
                          : false
                      }
                      height={responsiveData ? 14 : 16}
                      width={responsiveData ? 28 : 32}
                      handleDiameter={12}
                      offColor="#A8ADAF"
                      onColor="#000"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className={`${
                        responsiveData
                          ? istemplateLocal?.isPrompt !== undefined &&
                            istemplateLocal?.isPrompt
                            ? "small-on "
                            : "small-off "
                          : ""
                      }react-switch-Undragable`}
                    />
                  </div>
                </div>
                {(istemplateLocal?.isPrompt ?? isPrompt) && (
                  <div className="archi__section__main1__input">
                    <textarea
                      ref={textareaRef}
                      id="myTextArea"
                      placeholder={t("archi_sec_main1_inp")}
                      value={defaultText}
                      draggable={false}
                      style={{ resize: "none" }}
                      onChange={(e) =>{ handleTextAreaChange(e.target.value);
                        setTyping(true);
                      }}
                      className="auto-resize-textarea"
                      onFocus={handleTypingFocus}
                      onBlur={handleTypingBlur}
                    ></textarea>
                    <p
                      style={{ color: remaining === 1500 ? "red" : "inherit" }}
                    >
                      <span>{remaining}</span> / 1500
                    </p>
                  </div>
                )}
              </div>
              {(istemplateLocal?.isPrompt ?? isPrompt) && (
                <div className="archi__section__main2">
                  <div
                    className="archi__section__main2__heading"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("negative_prompt")}</h4>
                    <div
                      className="tooltip-top"
                      onClick={(event) => handleActiveLearn(25, event)}
                    >
                      {!responsiveData && <img src={intro} alt="intro" />}
                      <Portal
                        style={{
                          display: `${
                            activeLearnBool?.boolean === true &&
                            activeLearnBool?.id === 25
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {!responsiveData && (
                          <TipsPopups1
                            heading={t("negative_prompt")}
                            content={t("negative_prompt_tooltip")}
                            styles="0px"
                            image={NegPromptImage}
                            position={activeLearnBool?.position}
                          />
                        )}
                      </Portal>
                    </div>
                  </div>
                  <div className="archi__section__main2__input">
                    <textarea
                      ref={textarea1Ref}
                      id="myTextArea1"
                      placeholder={t("negative_prompt_placeholder")}
                      value={defaultText1}
                      draggable={false}
                      style={{ resize: "none" }}
                      onChange={(e) => {handleTextAreaChange1(e.target.value)}}
                      className="auto-resize-textarea"
                      onFocus={handleTypingFocus}
                      onBlur={handleTypingBlur}
                    ></textarea>
                    <p
                      style={{ color: remaining1 === 800 ? "red" : "inherit" }}
                    >
                      <span>{remaining1}</span> / 800
                    </p>
                  </div>
                </div>
              )}
              <div className="archi__section__main3">
                <div
                  className={`archi__section__main3__heading ${
                    istemplateLocal?.isPrivate ? "" : "off"
                  }`}
                  onClick={() => handleTemplate(4)}
                >
                  <div
                    className="archi__section__main3__heading__left"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("private_model")}</h4>
                    <div
                      className="tooltip-top"
                      onClick={(event) => handleActiveLearn(27, event)}
                    >
                      {!responsiveData && <img src={intro} alt="intro" />}
                      <Portal
                        style={{
                          display: `${
                            activeLearnBool?.boolean === true &&
                            activeLearnBool?.id === 27
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {!responsiveData && (
                          <TipsPopups1
                            heading={t("private_model")}
                            content={t("private_model_tooltip")}
                            styles="0px"
                            image={privateCustomModel}
                            position={activeLearnBool?.position}
                          />
                        )}
                      </Portal>
                    </div>
                  </div>
                  <div className="archi__section__main3__heading__right">
                    <Switch
                      onChange={() => handleTemplate(4)}
                      checked={
                        istemplateLocal?.isPrivate !== undefined
                          ? istemplateLocal?.isPrivate
                          : false
                      }
                      height={responsiveData ? 14 : 16}
                      width={responsiveData ? 28 : 32}
                      handleDiameter={12}
                      offColor="#A8ADAF"
                      onColor="#000"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className={`${
                        responsiveData
                          ? istemplateLocal?.isPrivate !== undefined &&
                            istemplateLocal?.isPrivate
                            ? "small-on "
                            : "small-off "
                          : ""
                      }react-switch-Undragable`}
                    />
                  </div>
                </div>
                {isPrivate && (
                  <div className="archi__section__main3__options">
                    <div className="archi__section__main3__options__container">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={
                          modelSelected ||
                          (lang === "ch"
                            ? promptLocalData?.privateModelCh
                            : promptLocalData?.privateModel)
                        }
                        isDisabled={false}
                        isLoading={isModelDataLoad}
                        onChange={handleChange}
                        isClearable={false}
                        isSearchable={true}
                        name="model"
                        options={privateModelData}
                        styles={customStyles}
                      />
                      {/* <h5>
                        {promptLocalData?.privateModel?.defaultStrength ?? 1}
                      </h5> */}
                      {promptLocalData?.privateModel?.value !== 1 ? (
                        <input
                          className="input-value"
                          value={value}
                          type="number"
                          step={0.1}
                          min={0.1}
                          max={1}
                          onWheel={handleScroll}
                          onChange={handleChange1}
                        />
                      ) : (
                        <h5>0</h5>
                      )}
                    </div>
                    {privateModelError && <p>{t("private_model_error")}</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignLibraryComopnent;
